import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import React, { useState } from 'react';
import {
	Redirect,
	Route,
	Switch,
	useHistory, useParams, useLocation,
} from 'react-router-dom';
import AllMappingDimensions from 'relevant-shared/mappingDimensions/allMappingDimension';
import { availReportTypes } from 'relevant-shared/reportData/reportType';
import { getAllValuesWithMetaFromObj } from 'relevant-shared/prebid/bidParamUtils';
import {
	Adserver,
	AdvertiserMapping,
	AnalyticsSystems,
	Cdn,
	CxReports,
	DocumentTemplate,
	Dsp,
	GlobalSettingsObject,
	Invoice,
	MiscTasks,
	Reports,
	RevenueRules as RevenueRulesApi,
	Segment,
	Ssp,
	TagData,
	UserIdModule,
	listOfPublishers,
} from '../../api/relevant';
import AdvertiserMappingEditor from '../../components/AdvertiserMapping/index';
import AdvertiserMappingList from '../../components/AdvertiserMapping/list';
import AlarmList from '../../components/AlarmList';
import CintEditor from '../../components/CintEditor';
import CopyVerificationCode from '../../components/CopyVerificationCode';
import DocumentTemplateEdit from '../../components/DocumentTemplateEdit';
import DocumentTemplateList from '../../components/DocumentTemplateList';
import GlobalAdmin from '../../components/GlobalAdmin';
import HbManagement from '../../components/HbManagement';
import InvoiceWorkspace from '../../components/InvoiceWorkspace';
import InvoiceEditor from '../../components/InvoiceWorkspace/editor';
import JobButton from '../../components/JobButton';
import MarketSettings from '../../components/MarketSettings';
import PortfolioEditor from '../../components/PortfolioEditor';
import ReportList from '../../components/ReportList';
import RevenueRules from '../../components/RevenueRules';
import SegmentEdit from '../../components/SegmentEdit';
import SegmentList from '../../components/SegmentList';
import Spinner from '../../components/Spinner';
import TagEditor from '../../components/TagEditor';
import TagDataEditor from '../../components/TagEditor/tagDataEditor';
import TextField from '../../components/TextField';
import TriggerWorkspace from '../../components/TriggerWorkspace';
import UserIdModulesEditor from '../../components/UserIdModulesEditor';
import UserSegmentList from '../../components/UserSegmentList';
import { PageContentWrapper, SimpleContainer, SimpleFormContainer } from '../../components/Wrappers';
import Base from '../../layouts/Base';
import BrowserUtils from '../../lib/browserUtils';
import DateUtils from '../../lib/dateUtils';
import MiscUtils from '../../lib/miscUtils';
import SystemData from '../../lib/systemData';
import { stores } from '../../stores';
import SystemEditPage from '../SystemEditPage';

export function SegmentListPage(props) {
	return (
		<PageContentWrapper title="Segment list">
			<SimpleContainer
				component={SegmentList}
				init={async () => ({
					history: props.history,
					segments: MiscUtils.alphaSorted(
						await Segment.call(
							'getSegments',
							{ noFilters: true, calculateExtras: true },
						),
						'name',
					),
					fields: SegmentList.allFields,
					byWhitelistedSites: [],
					extraFilters: true,
				})}
			/>
		</PageContentWrapper>
	);
}

export function SegmentEditPage(props) {
	return (
		<PageContentWrapper title="Edit segment">
			<SimpleFormContainer
				component={SegmentEdit}
				init={async () => {
					const segments = await Segment.list();
					return {
						history: props.history,
						segments,
						model: segments.find((s) => s.id === props.match.params.id),
						onSubmit: () => {},
					};
				}}
			/>
		</PageContentWrapper>
	);
}

function ReportListPage(props) {
	return (
		<PageContentWrapper title={props.title} maxWidth="md">
			<SimpleContainer
				component={ReportList}
				init={async (opWrapper) => ({
					history: props.history,
					reports: await Reports.call('listReports', { tag: props.tag }),
					hrefBase: props.hrefBase,
					deleteReport: async (report) => {
						await Reports.call('deleteReport', { id: report.id });
						opWrapper.reload();
					},
				})}
			/>
		</PageContentWrapper>
	);
}

export function GenericReports() {
	return (
		<Switch>
			{availReportTypes.map(({ type, reportLocation, genericReportLocation }) => (
				<Route
					key={type}
					exact
					path={genericReportLocation}
					render={() => (<Redirect to={`${reportLocation}/add${window.location.search}`} />)}
				/>
			))}
		</Switch>
	);
}

export function RevenueRulesPage(props) {
	return (
		<PageContentWrapper title="Revenue rules" maxWidth="xl">
			<div style={{ minHeight: '5000px' /** stupid solution so solve "jumping page"-problems */ }}>
				<SimpleFormContainer
					component={RevenueRules}
					init={async (op) => {
						const goBack = () => props.history.push('/settings/audience-configurations');
						const model = (await RevenueRulesApi.list())[0];
						model.filterTransforms = MiscUtils.alphaSorted(model.filterTransforms, 'name');
						model.specificTransforms = MiscUtils.alphaSorted(model.specificTransforms, 'name');
						return {
							model,
							onSubmit: async (rules, settings) => {
								await op.reload(() => rules.update());
								if (!(settings || {}).stayOnPage) {
									goBack();
								}
							},
							onCancel: goBack,
						};
					}}
				/>
			</div>
		</PageContentWrapper>
	);
}

export function AlarmsPage() {
	return (
		<PageContentWrapper title="Alarms">
			<SimpleContainer
				component={AlarmList}
				init={() => {
					const urlParams = BrowserUtils.urlParams();
					let initDate = DateUtils.yesterday();
					if (urlParams.date) {
						initDate = new Date(urlParams.date);
						if (isNaN(initDate.getTime())) {
							throw Error(`Invalid date '${urlParams.date}'`);
						}
					}
					return { initDate, initCategory: urlParams.category || 'normal' };
				}}
				counterGridNegativeMargin
			/>
		</PageContentWrapper>
	);
}

export function MySegmentsPage(props) {
	return (
		<PageContentWrapper title="My segments">
			<SimpleContainer
				component={UserSegmentList}
				init={async () => {
					let { cX } = window;
					if (!cX) {
						window.cX = { callQueue: [] };
						cX = window.cX;
						cX.callQueue.push(['setSiteId', '1135123498657212681']);
						cX.callQueue.push(['sendPageViewEvent']);
						const scriptElm = document.createElement('script');
						scriptElm.type = 'text/javascript';
						scriptElm.async = 'async';
						scriptElm.src = 'https://scdn.cxense.com/cx_plain.js';
						document.head.appendChild(scriptElm);
					}
					for (let i = 0; i < 20; i++) {
						await new Promise((r) => setTimeout(r, 500));
						const cxId = cX.getCxenseUserId ? cX.getCxenseUserId() : null;
						if (cxId) {
							return {
								cxId,
								segments: await CxReports.call('getUserSegments', { cxId, isPublic: props.isPublic }),
							};
						}
					}
					throw Error('Failed to get Cxense ID');
				}}
			/>
		</PageContentWrapper>
	);
}

function SimpleHeaderBox(props) {
	const { logoUrl } = props;
	return (
		<Box
			py={5}
			marginBottom={5}
			textAlign="center"
			bgcolor="white"
			border={10}
			borderTop={0}
			borderRight={0}
			borderLeft={0}
			borderColor="secondary.main"
		>
			{(logoUrl && <img src={logoUrl} alt="logo" style={{ width: '50%' }} />)}
		</Box>
	);
}
export function SelectPasswordPage() {
	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [succeeded, setSucceeded] = useState(false);
	const { loginLogoUrl } = SystemData.genericData.systemSettings;
	const { token } = useParams();
	const history = useHistory();

	if (!token) {
		history.push('/login');
		return null;
	}
	return (
		<>
			<SimpleHeaderBox logoUrl={loginLogoUrl} />
			<Container>
				<Paper>
					<Box padding={2}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<TextField
									name="password"
									label="Password"
									fullWidth
									onChange={(e) => setPassword(e.target.value)}
									password
									value={password}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									name="newPassword"
									label="Repeat password"
									fullWidth
									onChange={(e) => setNewPassword(e.target.value)}
									password
									value={newPassword}
								/>
							</Grid>
							<Grid item xs={12}>
								<Box textAlign="center">
									{succeeded ? (
										<Typography align="center" color="green">
											Password has been changed.
										</Typography>
									) : (
										<JobButton
											label="Select password"
											fn={async () => {
												if (password !== newPassword) {
													throw new Error('Passwords does not match.');
												}
												const params = { oldPassword: password, newPassword, token };
												await MiscTasks.call('changeMyPassword', params);
												setSucceeded(true);
												history.push('/login');
											}}
											color="secondary"
										/>
									)}
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Paper>
			</Container>
		</>
	);
}

export function OAuthSimplePage() {
	const location = useLocation();
	const [message, setMessage] = useState('Authenticating...');
	const urlParams = new URLSearchParams(location.search);

	const code = urlParams.get('code');
	const redirect = urlParams.get('state');

	if (code && redirect) {
		const searchDelimiter = redirect.includes('?') ? '&' : '?';
		const goTo = `${redirect}${searchDelimiter}code=${encodeURIComponent(code)}`;
		window.location = goTo;
	} else {
		return (<Spinner text={message} delay />);
	}
}

export function CopyAuthCodePage() {
	return (
		<SimpleContainer
			component={CopyVerificationCode}
			init={() => ({
				code: BrowserUtils.urlParams().code || throw Error('No verification code supplied'),
			})}
		/>
	);
}

export function DspEdit(props) {
	return (
		<SystemEditPage
			{...props}
			vars={['segments', 'pricelists']}
			backUrl="/settings/audience-configurations"
			type={Dsp}
			desc="Dsp"
		/>
	);
}

const withPubId = (url) => {
	const { publisherId } = BrowserUtils.urlParams();
	return publisherId ? BrowserUtils.makeQs(url, { publisherId }) : url;
};

export function SspEdit(props) {
	return (
		<SystemEditPage
			{...props}
			backUrl={withPubId('/settings/programmatic-configurations')}
			type={Ssp}
			desc="Ssp"
		/>
	);
}

export function AdserverEdit(props) {
	return (
		<SystemEditPage
			{...props}
			backUrl={withPubId('/settings/programmatic-configurations')}
			type={Adserver}
			desc="Adserver"
		/>
	);
}

export function AnalyticsEdit(props) {
	return (
		<SystemEditPage
			{...props}
			backUrl={withPubId('/settings/programmatic-configurations')}
			type={AnalyticsSystems}
			desc="Analytics"
		/>
	);
}

export function UserIdModulesEdit(props) {
	return (
		<UserIdModulesEditor
			{...props}
			backUrl={withPubId('/settings/programmatic-configurations')}
			type={UserIdModule}
			desc="User ID module"
		/>
	);
}

export function GlobalSettingsMarket() {
	return (
		<PageContentWrapper title="Global Market Settings">
			<SimpleContainer component={MarketSettings} />
		</PageContentWrapper>
	);
}

const tagFieldsPage = ({
	props, title, backLink, tagVar, loadDefaults, onGlobalSettings, noJsOutput,
}) => (
	<PageContentWrapper title={title}>
		<SimpleFormContainer
			component={TagEditor}
			init={async (op) => {
				const getGlob = async () => (await GlobalSettingsObject.list())[0];
				const globalSettings = await getGlob();
				if (onGlobalSettings) {
					onGlobalSettings(globalSettings, props);
				}
				const goBack = backLink ? BrowserUtils.goBackFn(props.history, { url: backLink }) : null;
				return {
					model: globalSettings[tagVar],
					onSubmit: async (data) => {
						await op.reload(async () => {
							await globalSettings.update({ [tagVar]: data });
						});
						if (goBack) {
							goBack();
						}
					},
					loadDefaults: loadDefaults ? () => loadDefaults(globalSettings) : null,
					onCancel: goBack,
					noJsOutput,
				};
			}}
		/>
	</PageContentWrapper>
);

export const AudienceTagFields = (props) => tagFieldsPage({
	props,
	title: 'Audience Tag Fields',
	backLink: '/settings/audience-configurations',
	tagVar: 'audienceTag',
});

export const PlacementTagFields = (props) => tagFieldsPage({
	props,
	title: 'Generic Placement Data Fields',
	backLink: null,
	tagVar: 'placementTag',
	noJsOutput: true,
});

export const PbConfigTagFields = (props) => tagFieldsPage({
	props,
	title: 'Generic Prebid Config Data Fields',
	backLink: null,
	tagVar: 'pbConfigTag',
	noJsOutput: true,
});

export const SiteTagFields = (props) => tagFieldsPage({
	props,
	title: 'Generic Site Data Fields',
	backLink: null,
	tagVar: 'siteTag',
	noJsOutput: true,
});

export function TagDataEditorPage(props) {
	return (
		<PageContentWrapper title="Edit tag data">
			<SimpleFormContainer
				component={TagDataEditor}
				init={async (op) => {
					const { type, id, accountId } = BrowserUtils.urlParams();
					const isProgrammatic = _.includes(['programmaticMasterTag', 'sspPublisherId', 'sspSiteId'], type);
					const defaultBack = isProgrammatic ? '/settings/programmatic-configurations' : '/settings/audience-configurations';
					const backUrl = accountId ? `/accounts/${accountId}` : defaultBack;
					const dataId = { type, id };
					const goBack = () => props.history.push(backUrl);
					const { data, info } = await TagData.call('getTagData', { dataId });
					getAllValuesWithMetaFromObj(data, info.orgValueData, (val, orgValue) => {
						val.orgValue = orgValue;
					});
					return {
						model: data,
						info,
						submitOnEnter: false,
						type: isProgrammatic ? 'programmatic' : 'audience',
						onSubmit: async (newData) => {
							await op.reload(async () => {
								await TagData.call('writeTagData', { dataId, data: newData });
							});
							goBack();
						},
						onCancel: goBack,
						match: props.match,
						location: props.location,
					};
				}}
			/>
		</PageContentWrapper>
	);
}

export function CintEditorPage(props) {
	return (
		<PageContentWrapper title="Cint Segments">
			<SimpleContainer
				component={CintEditor}
				init={() => {
					const { history } = props;
					return {
						onCancel: BrowserUtils.goBackFn(history),
					};
				}}
			/>
		</PageContentWrapper>
	);
}

export const DocumentTemplatePage = (props) => ((id) => (
	<PageContentWrapper title={!id ? 'New Document Template' : 'Edit Document Template'}>
		<SimpleFormContainer
			component={DocumentTemplateEdit}
			init={async (op) => {
				const { history } = props;
				const goBack = BrowserUtils.goBackFn(history);
				const [model, types] = await Promise.all([
					DocumentTemplate.get(id),
					DocumentTemplate.call('getTypes'),
				]);
				return {
					model,
					types,
					onSubmit: async () => {
						await op.reload(async () => {
							await (id ? model.update() : DocumentTemplate.add(model));
							goBack();
						});
					},
					onCancel: goBack,
				};
			}}
		/>
	</PageContentWrapper>
))(props.match.params.id === 'add' ? null : props.match.params.id);

export function DocumentTemplateListPage() {
	return (
		<PageContentWrapper title="Document Templates">
			<SimpleContainer
				component={DocumentTemplateList}
				init={async () => ({ types: await DocumentTemplate.call('getTypes') })}
				counterGridNegativeMargin
			/>
		</PageContentWrapper>
	);
}

export function AdvertiserMappingListPage(props) {
	const isAdmin = stores.identity.isAdministrator();
	const mappingDim = AllMappingDimensions.byName(BrowserUtils.urlParams().dimType);
	return (
		<PageContentWrapper title={isAdmin ? 'Advertisers/Buyers and Mappings' : 'Advertiser/Buyer Mappings'} pageStyle={{ overflowX: 'none' }}>
			<SimpleContainer
				component={AdvertiserMappingList}
				init={() => ({
					path: props.match.path,
					editIndividualAdvertisers: isAdmin,
					initTabSelected: AllMappingDimensions.indexOf(mappingDim),
				})}
			/>
		</PageContentWrapper>
	);
}

export const AdvertiserMappingPage = (props) => ((id) => {
	const { name: dimType, uiCName } = AllMappingDimensions.byName(BrowserUtils.urlParams().dimType);
	return (
		<PageContentWrapper
			title={!id ? `New ${uiCName} Mapping` : `Edit ${uiCName} Mapping`}
			maxWidth="xl"
		>
			<SimpleFormContainer
				component={AdvertiserMappingEditor}
				init={async (op) => {
					const publisherId = stores.identity.publisherId();
					const [model, all] = await Promise.all([
						id ? AdvertiserMapping.get(id) : { publisherId, mappings: [], dimType },
						AdvertiserMapping.call('getAll'),
					]);
					const goBack = () => props.history.push(
						`/${publisherId ? '' : 'settings'}/advertiser-mappings?dimType=${model.dimType}`,
					);
					return {
						model,
						all,
						canCreateAdvertisers: stores.identity.isAdministrator(),
						onSubmit: async () => {
							await op.reload(async () => {
								await (id ? model.update(null, { updateUsingDiff: true }) : AdvertiserMapping.add(model));
								goBack();
							});
						},
						onCancel: goBack,
					};
				}}
			/>
		</PageContentWrapper>
	);
})(props.match.params.id === 'add' ? null : props.match.params.id);

export function PortfolioEditorPage(props) {
	return (
		<PageContentWrapper title="Users and portfolios">
			<SimpleContainer
				component={PortfolioEditor}
				init={() => {
					const { history } = props;
					return {
						onCancel: BrowserUtils.goBackFn(history),
					};
				}}
				counterGridNegativeMargin
			/>
		</PageContentWrapper>
	);
}

export function InvoicingPage() {
	return (
		<PageContentWrapper title="Invoices" maxWidth="xl">
			<SimpleContainer
				component={InvoiceWorkspace}
				init={async () => {
					const [pubs, docTemplates] = await Promise.all([
						listOfPublishers(),
						DocumentTemplate.list({ sortby: 'name' }),
					]);
					return { docTemplates, publishers: pubs.result, isAdmin: true };
				}}
			/>
		</PageContentWrapper>
	);
}

function UserInvoicingPage(props) {
	return (
		<PageContentWrapper title={props.title} maxWidth="xl">
			<SimpleContainer
				component={InvoiceWorkspace}
				init={() => ({
					docTemplates: [],
					publishers: [],
					isAdmin: false,
					onlyProduct: props.product,
				})}
			/>
		</PageContentWrapper>
	);
}

export function AudienceUserInvoingPage() {
	return (
		<UserInvoicingPage
			product="audience"
			title="Audience invoices and info letters"
		/>
	);
}

export function ProgrammaticUserInvoingPage() {
	return (
		<UserInvoicingPage
			product="programmatic"
			title="Programmatic invoices and info letters"
		/>
	);
}

export function InvoiceEditorPage(props) {
	return (
		<PageContentWrapper title="Edit invoice">
			<SimpleFormContainer
				component={InvoiceEditor}
				init={async (op) => {
					const goBack = () => props.history.push('/invoicing');
					const model = await Invoice.get(props.match.params.id);
					return {
						model,
						onSubmit: async () => {
							await op.reload(async () => {
								await model.update();
								goBack();
							});
						},
						onCancel: goBack,
						saveAndSend: async () => {
							await op.reload(async () => {
								await model.update();
								await model.publish();
								goBack();
							});
						},
						reGenerate: async () => {
							await op.reload(async () => {
								await model.update();
								await model.reGenerate();
								await op.runFn();
							});
						},
					};
				}}
			/>
		</PageContentWrapper>
	);
}

export function HbManagementPage() {
	return (
		<PageContentWrapper title="HB Management">
			<SimpleContainer
				component={HbManagement}
			/>
		</PageContentWrapper>
	);
}

export function GlobalAdminPage() {
	return (
		<PageContentWrapper title="Global admin">
			<GlobalAdmin />
		</PageContentWrapper>
	);
}

export function TriggerWorkspacePage() {
	return (
		<PageContentWrapper title="Alarms" maxWidth="xl">
			<Base.GlobalComponents.Consumer>
				{(globalComponents) => (
					<SimpleContainer
						component={TriggerWorkspace}
						init={() => ({ globalComponents })}
					/>
				)}
			</Base.GlobalComponents.Consumer>
		</PageContentWrapper>
	);
}
