import PropTypes from 'prop-types';
import React from 'react';
import withTheme from '@mui/styles/withTheme';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { OutlinedInput } from '@mui/material';
import { getFormPropsFor } from '../../containers/Form';

let instanceCounter = 0;

class TextField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: `TextField-${++instanceCounter}`,
		};
	}

	getInputType() {
		const {
			integer, float, email, password, type,
		} = this.props;
		if (integer || float) {
			return 'number';
		} if (email) {
			return 'email';
		} if (password) {
			return 'password';
		}
		return type;
	}

	getStyles() {
		const { color, theme } = this.props;
		if (color) {
			const path = color.includes('.') ? color : `${color}.main`;
			return { color: _.get(theme.palette, path) };
		}
		return undefined;
	}

	render() {
		const {
			name,
			label,
			help,
			fullWidth,
			multiLine,
			onClick,
			onMouseEnter,
			onMouseLeave,
			nonEditable,
			onChange,
			className,
			required,
			margin,
			disabled,
			startAdornment,
			endAdornment,
			shrink,
			autoComplete,
			hideLabelIfEmpty,
			inputProps,
			uncontrolled,
			defaultValue,
			allowEnter,
			placeholder,
			inputLabelProps,
			outlinedInputProps,
			formStyle,
		} = this.props;
		const { id } = this.state;
		const { error, value } = getFormPropsFor(this);
		const helpText = error || help;

		const manualNotch = {};
		if (!!value && nonEditable) {
			manualNotch.notched = true;
		}
		return (
			<FormControl
				error={Boolean(error)}
				fullWidth={fullWidth}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				className={className}
				required={required}
				margin={margin}
				disabled={disabled}
				style={formStyle}
			>
				{/* TODO: Remove hideLabelIEmpty.
				InputLabel should not be rendered if empty, even without hideLabelIfEmpty. But there's MANY <Select>, so make sure not to break the design... */}
				{ ((!hideLabelIfEmpty || label) && (
					<InputLabel
						id={`${id}_label`}
						shrink={shrink || (nonEditable === true && (Boolean(value) || value === 0)) || undefined}
						{...inputLabelProps}
					>
						{label}
					</InputLabel>
				))}
				<OutlinedInput
					id={id}
					aria-describedby={helpText ? `${id}-help` : undefined}
					name={name}
					value={(value === undefined || value === null) && !uncontrolled ? '' : value}
					defaultValue={defaultValue}
					onChange={(event) => onChange(event, this.props)}
					onKeyPress={(event) => {
						if (!allowEnter && !multiLine && event.key === 'Enter') {
							event.preventDefault();
							event.stopPropagation();
						}
					}}
					type={this.getInputType()}
					style={this.getStyles()}
					multiline={multiLine}
					readOnly={nonEditable}
					endAdornment={endAdornment}
					startAdornment={startAdornment}
					autoComplete={autoComplete}
					inputProps={inputProps}
					// manually handle notched case in this situation
					{...manualNotch}
					// this label is actually hidden,
					// just a trick to make the label not overlined
					// in future we should use Material TextField for this
					label={label}
					placeholder={placeholder}
					{...outlinedInputProps}
				/>
				{helpText && (
					<FormHelperText id={`${id}-help`}>
						{helpText}
					</FormHelperText>
				)}
			</FormControl>
		);
	}
}

TextField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	error: PropTypes.string,
	help: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	fullWidth: PropTypes.bool,
	password: PropTypes.bool,
	email: PropTypes.bool,
	integer: PropTypes.bool,
	float: PropTypes.bool,
	nonEditable: PropTypes.bool,
	multiLine: PropTypes.bool,
	onClick: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	className: PropTypes.string,
	required: PropTypes.bool,
	margin: PropTypes.oneOf(['none', 'dense', 'normal']),
	disabled: PropTypes.bool,
	startAdornment: PropTypes.node,
	endAdornment: PropTypes.node,
	color: PropTypes.string,
	shrink: PropTypes.bool,
	autoComplete: PropTypes.oneOf(['on', 'off']),
	hideLabelIfEmpty: PropTypes.bool,
	inputProps: PropTypes.object,
	uncontrolled: PropTypes.bool,
	defaultValue: PropTypes.string,
	allowEnter: PropTypes.bool,
	placeholder: PropTypes.string,
	inputLabelProps: PropTypes.object,
	outlinedInputProps: PropTypes.object,
	formStyle: PropTypes.object,
};

TextField.defaultProps = {
	fullWidth: false,
	type: 'text',
	password: false,
	email: false,
	integer: false,
	float: false,
	nonEditable: false,
	multiLine: false,
	onClick: undefined,
	onMouseEnter: undefined,
	onMouseLeave: undefined,
	onChange: undefined,
	className: '',
	required: false,
	margin: undefined,
	disabled: false,
	startAdornment: undefined,
	endAdornment: undefined,
	color: undefined,
	shrink: false,
	autoComplete: 'on',
	hideLabelIfEmpty: false,
	inputProps: undefined,
	uncontrolled: false,
	defaultValue: undefined,
	allowEnter: false,
	placeholder: undefined,
	inputLabelProps: undefined,
	outlinedInputProps: undefined,
	formStyle: undefined,
};

export default withTheme(TextField);
