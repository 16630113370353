/* eslint-disable react/prop-types */
import React from 'react';
import Grid from '@mui/material/Grid';
import _ from 'lodash';
import { AdRespFields, Conditions } from 'relevant-shared/appnexus/constants';
import ExternalSystemEdit from '../components/ExternalSystemEdit';
import { PopupForm } from '../components/Wrappers';
import Checkbox from '../components/Checkbox';
import FieldArray from '../components/FieldArray';
import TextField from '../components/TextField';
import Select from '../components/Select';
import ExpandSelector from '../components/ExpandSelector';
import classes from '../api/classes';

const { AppnexusRtImporterExcludeCondition } = classes;

const getFullPubList = (pubs, selected) => [
	..._.difference(_.sortBy(selected), _.map(pubs, 'id')).map((id) => ({
		id, name: `[UNKNOWN id: ${id}]`, isUnknown: true,
	})),
	..._.sortBy(pubs, 'name'),
];

ExternalSystemEdit.registerCustomRenderer('AppnexusPubFilter', ({ system, sysProps: { form } }) => (
	<PopupForm
		selected={system.publisherFilter}
		title="Filtered publishers"
		size="sm"
		getModel={() => _.cloneDeep(system.publisherFilter)}
		fn={() => system.listPublishers()}
		content={({ opResult: pubs, model: publisherFilter, popup }) => (
			<>
				{getFullPubList(pubs, publisherFilter).map(({ name, id }) => (
					<Grid container key={id}>
						<Grid item xs="auto">
							<Checkbox
								formStyle={{ marginTop: 0, marginBottom: 0 }}
								label={name}
								name={name + id}
								value={publisherFilter.includes(id)}
								onChange={() => popup.update(() => {
									if (publisherFilter.includes(id)) {
										_.pull(publisherFilter, id);
									} else {
										publisherFilter.push(id);
									}
								})}
								margin="normal"
							/>

						</Grid>
					</Grid>
				))}
			</>
		)}
		onApplyChanges={({ model: publisherFilter }) => form.setVals({ publisherFilter })}
	/>
));

const AppnexusRtExclusionRulesEdit = ({ parentProps: p }) => {
	return (
		<ExpandSelector
			title="Custom exclusion rules"
			selected={p.model.excludeConds}
		>
			<FieldArray
				{...p}
				noSwapBtns
				name="excludeConds"
				update={() => p.form.update()}
				createNew={() => new AppnexusRtImporterExcludeCondition()}
				beforeHeader={(fld) => (
					<Grid container spacing={1}>
						<Grid item xs={3}>
							<Select
								fullWidth
								label="Field"
								{...fld('field')}
								items={AdRespFields.map((o) => ({ label: o.description, value: o.name }))}
							/>
						</Grid>
						<Grid item xs={3}>
							<Select
								fullWidth
								label="Condition"
								{...fld('cond')}
								items={Conditions.map((o) => ({ label: o.description, value: o.name }))}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								{...fld('toMatch')}
								required
								label="Match string"
								fullWidth
							/>
						</Grid>
					</Grid>
				)}
			/>
		</ExpandSelector>
	);
};

ExternalSystemEdit.registerCustomRenderer('AppnexusRtExclusionRulesEdit', AppnexusRtExclusionRulesEdit);
