/* eslint-disable react/prop-types, react/no-unstable-nested-components, react/function-component-definition, react/destructuring-assignment */
import React from 'react';
import Grid from '@mui/material/Grid';
import _ from 'lodash';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { createCsv } from 'relevant-shared/misc/misc';
import ExternalSystemEdit from '../components/ExternalSystemEdit';
import { FormOf } from '../components/Wrappers';
import TextField from '../components/TextField';
import FieldArray from '../components/FieldArray';
import CsvImport from '../components/CsvImport';
import { ActionButton } from '../components/ActionButton/ActionButton';
import BrowserUtils from './browserUtils';
import PopupSelector from '../components/PopupSelector';

const thinTextField = (p) => (
	<TextField
		shrink
		formStyle={{ margin: 0, marginRight: 10 }}
		required
		isolated
		margin="normal"
		inputProps={{ style: { paddingTop: 8, paddingBottom: 8, width: 150 } }}
		inputLabelProps={{ style: { background: 'white' } }}
		{...p}
	/>
);

const CpmList = ({ field, model, form }) => (
	<FieldArray
		noSwapBtns
		model={model}
		field={field}
		name="arr"
		update={() => form.update()}
		createNew={() => ({ hash: '', cpm: 0 })}
		boxProps={{ marginBottom: 1 }}
		renderContainer={(content, header) => (
			<Card>
				<CardContent style={{ margin: 0, paddingTop: 6, paddingBottom: 0 }}>
					{header}
				</CardContent>
			</Card>
		)}
		beforeHeader={(fld) => (
			<>
				{thinTextField({
					float: true,
					between: { low: 0, high: 2000 },
					label: 'CPM',
					...fld('cpm'),
				})}
				{thinTextField({ label: 'Hash code', ...fld('hash') })}
			</>
		)}
	/>
);

const MainEdit = (p) => {
	const setFromArray = (arr) => p.form.setVals({ arr }, true).then(() => p.form.update());
	return (
		<Grid container>
			<Grid item xs={12} sx={{ mb: 2 }}>
				<Card>
					<CardContent>
						<Grid container>
							<Grid item xs={4}>
								<CsvImport
									colSelectors={[
										{ name: 'cpm', label: 'CPM Column', required: true, toNumber: true },
										{ name: 'hash', label: 'Hash code column', required: true },
									]}
									onCsvImported={setFromArray}
								/>

							</Grid>
							<Grid item xs={4}>
								<ActionButton
									label="Export to .csv"
									onClick={() => BrowserUtils.downloadTextFile(
										createCsv(p.model.arr, { cpm: 'CPM', hash: 'Hash code' }),
										'amazonCpmMapping.csv',
									)}
								/>
							</Grid>
							<Grid item xs={4}>
								<ActionButton
									label="Clear all"
									icon={<DeleteIcon />}
									onClick={() => setFromArray([])}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h2">
							CPM to hash code mapping
						</Typography>
						<CpmList {...p} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

const AmazonCpmMappingEdit = ({ system }) => (
	<FormOf
		model={{}}
		content={(p) => (
			<PopupSelector
				title="CPM to Amazon hash mapping"
				size="sm"
				form={p.form}
				allowErrorsIfCancel
				fn={async () => {
					const { cpmMap } = await system.getAmazonInfo() || {};
					await p.form.setVals({
						arr: _.sortBy(_.entries(cpmMap).map(([hash, cpm]) => ({ hash, cpm })), 'cpm'),
					}, true);
				}}
				content={() => <MainEdit {...p} />}
				onApplyChanges={() => system.setAmazonInfo({
					info: { cpmMap: _.mapValues(_.keyBy(p.model.arr, 'hash'), (v) => Number(v.cpm)) },
				})}
			/>
		)}
	/>
);

ExternalSystemEdit.registerCustomRenderer('AmazonCpmMappingEdit', AmazonCpmMappingEdit);
