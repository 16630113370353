const _ = require('lodash');
const { CURRENCIES } = require('../constants');

const UI_GROUPS = {
	BUILT_IN: 'Built-in settings',
	ADV_ORDER: 'Advertiser/Order',
};

const FILTERS = {
	LINE_ITEM_TYPES: 'Line item types',
	DESC_STRING: `Line item types/Advertisers/Orders`,
};

const hasFloorProp = ({ node, adservers }, propName) => {
	const ads = node?.type === 'PlacementNode' ? [node.adserver] : adservers;
	return !!ads?.some((a) => a?.floorProps?.[propName]);
};

module.exports = [
	{
		type: 'Number',
		uiGroup: UI_GROUPS.BUILT_IN,
		name: 'rlvFloor',
		description: 'Price floor',
		omitWhenDefault: true,
	},
	{
		type: 'Boolean',
		uiGroup: UI_GROUPS.BUILT_IN,
		name: 'rlvFloorOpt',
		description: 'Optimize floor price',
		omitWhenDefault: true,
		optiField: true,
	},
	{
		type: 'Boolean',
		uiGroup: UI_GROUPS.BUILT_IN,
		name: 'rlvFloorOptOnly',
		description: 'Only set price floor for optimized users',
		omitWhenDefault: true,
		optiField: true,
	},
	{
		type: 'Boolean',
		uiGroup: UI_GROUPS.BUILT_IN,
		name: 'rlvFloorOptAllowNo',
		description: 'Allow optimizer to disable floor even when non-zero',
		omitWhenDefault: true,
		optiField: true,
	},
	{
		type: 'String',
		uiGroup: UI_GROUPS.BUILT_IN,
		name: 'rlvFloorCur',
		description: 'Price floor currency',
		defaultValue: null,
		hasOptions: true,
		options: Object.entries(CURRENCIES).map(([code, { name }]) => ({
			name: code,
			label: `${name} (${code})`,
		})),
		omitWhenDefault: true,
	},
	{
		type: 'String',
		uiGroup: UI_GROUPS.BUILT_IN,
		name: 'rlvFloorType',
		description: 'Set price floors for',

		// Hide setting for placements where adserver can't use floors anyway, except if it's set to 'adsOnly'
		shouldHide: (ctx, { value }) => !hasFloorProp(ctx, 'canSetFloors') && value !== 'adsOnly',
		isRequired: true,
		defaultValue: 'hbOnly',
		omitWhenDefault: true,
		hasOptions: true,
		options: [
			{
				name: 'both',
				label: 'Both Headerbidding and Adserver',
			},
			{
				name: 'hbOnly',
				label: 'Headerbidding only',
			},
			{
				name: 'adsOnly',
				label: 'Adserver only',
			},
		],
	},
	{
		type: 'Number',
		uiGroup: UI_GROUPS.BUILT_IN,
		name: 'rlvAdsOptiPerc',
		description: 'Adserver optimized %',

		// Hide setting for placements where adserver can't optimize anyway
		shouldHide: (ctx) => !hasFloorProp(ctx, 'canAdsOptimize'),
		omitWhenDefault: true,
		props: {
			between: { low: 0, high: 100 },
			help: 'Adserver optimized users will ignore any price floor set above',
		},
	},
	{
		type: 'Boolean',
		uiGroup: UI_GROUPS.BUILT_IN,
		name: 'rlvDisableHb',
		description: 'Disable all headerbidding',
		omitWhenDefault: true,
		props: { help: 'Notice: Disabling of headerbidding using this setting only works for web' },
	},
	{
		// Essentially maps to disableCollapse option for appnexus renderer settings
		type: 'Object',
		uiGroup: UI_GROUPS.BUILT_IN,
		name: 'rlvReloadSettings',
		description: 'Reload settings',
		defaultValue: {},
		fields: [
			{
				type: 'Boolean',
				name: 'enabled',
				description: 'Enable placement reloading',
				defaultValue: false,
			},
			{
				type: 'Number',
				name: 'timeBetween',
				description: 'Reload interval in seconds',
				defaultValue: 60,
				isRequired: true,
			},
			{
				type: 'String',
				name: 'strategy',
				description: 'Count reload interval by',
				defaultValue: 'load',
				isRequired: true,
				hasOptions: true,
				options: [
					{
						name: 'load',
						label: 'Time since loaded',
					},
					{
						name: 'viewStart',
						label: 'Time since first time viewable',
					},
					{
						name: 'sticky',
						label: 'Time being viewable',
					},
				],
			},
			{
				type: 'String',
				name: 'hiddenType',
				description: 'Count the page as visible',
				defaultValue: 'focus',
				hasOptions: true,
				isRequired: true,
				omitWhenDefault: true,
				options: [
					{
						name: 'focus',
						label: 'When document is focused',
					},
					{
						name: 'hidden',
						label: 'When document is not hidden',
					},
					{
						name: 'none',
						label: 'Always (no check, not recommended)',
					},
				],
			},
			{
				type: 'Number',
				name: 'reloadTimes',
				description: 'Maximum number of reloads',
				defaultValue: 2,
				isRequired: true,
			},
			{
				type: 'Number',
				name: 'minVisibility',
				description: 'Minimum viewability % for reload',
				defaultValue: 70,
				isRequired: true,
			},
			{
				type: 'Boolean',
				name: 'keepDims',
				description: 'Set CSS min width/height when reloading (prevents jump-effect)',
				defaultValue: false,
			},
			{
				type: 'Boolean',
				name: 'allowPostMessage',
				description: 'Allow updating settings via postMessage API',
				defaultValue: false,
				omitWhenDefault: true,
			},
			{
				type: 'String',
				name: 'fType',
				description: 'Filter type',
				defaultValue: 'incl',
				isRequired: true,
				hasOptions: true,
				options: [
					{
						name: 'incl',
						label: `Include ${FILTERS.DESC_STRING}`,
					},
					{
						name: 'excl',
						label: `Exclude ${FILTERS.DESC_STRING}`,
					},
				],
			},
			{
				type: 'Array',
				description: `Filter ${FILTERS.DESC_STRING} (only supported in GAM)`,
				defaultValue: [],
				name: 'filter',
				fields: [
					{
						type: 'String',
						name: 'liType',
						description: `${FILTERS.LINE_ITEM_TYPES} (requires GAM Real time import)`,
						hasOptions: true,
						multiSelect: true,
						options: [
							{
								name: 'hb',
								label: 'Headerbidding',
							},
							// "name" must match enum values in AdserverLineItemType
							...['Sponsorship', 'Standard', 'Network', 'Bulk', 'Price Priority', 'House',
								'Click Tracking', 'Ad Exchange', 'Adsense', 'Bumper', 'Admob', 'Preferred Deal']
								.map((label, idx) => ({ label, name: (idx + 1).toString() })),
						],
					},
					{
						uiGroup: UI_GROUPS.ADV_ORDER,
						type: 'String',
						name: 'type',
						description: 'Object type',
						defaultValue: 'adv',
						isRequired: true,
						hasOptions: true,
						options: [
							{
								name: 'adv',
								label: 'Advertiser ID',
							},
							{
								name: 'order',
								label: 'Order ID',
							},
						],
					},
					{

						uiGroup: UI_GROUPS.ADV_ORDER,
						type: 'String',
						name: 'value',
						description: 'ID (can be left blank if line item type is selected)',
					},
				],
			},
		],
	},
	{
		// Essentially maps to disableCollapse option for appnexus renderer settings
		type: 'Object',
		uiGroup: UI_GROUPS.BUILT_IN,
		name: 'rlvLazy',
		description: 'Lazy load settings',
		defaultValue: {},
		fields: [
			{
				type: 'Boolean',
				name: 'enabled',
				description: 'Lazy loading',
				defaultValue: false,
			},
			{
				type: 'Number',
				name: 'pixels',
				description: 'Pixel distance from screen (vertically)',
				defaultValue: 0,
				isRequired: true,
			},
			{
				type: 'Number',
				name: 'mobileScaling',
				description: 'Multiplier applied to pixel distance on mobile devices',
				defaultValue: 1,
				isRequired: true,
				omitWhenDefault: true,
			},
		],
	},
];
