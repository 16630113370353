module.exports = {
	AdRespFields: [
		{
			name: 'bannerContent',
			description: 'Banner content',
			path: ['banner', 'content'],
		},
		{
			name: 'creative',
			description: 'Creative ID',
			path: ['creativeId'],
		},
		{
			name: 'mediaType',
			description: 'Media type ID',
			path: ['mediaTypeId'],
		},
	],
	Conditions: [
		{
			name: 'contains',
			description: 'Contains',
			code: 'str.indexOf(toMatch) >= 0',
		},
		{
			name: 'doesntContain',
			description: 'Doesn\'t contain',
			code: 'str.indexOf(toMatch) < 0',
		},
	],
};
