import React, { Fragment } from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Alert } from '@mui/material';
import HbCustomizer from 'relevant-shared/reportData/customizers/hbCustomizer';
import {
	VALUE_REPORT_COMPATIBLE_DIMENSIONS,
	isOptiMetric,
} from 'relevant-shared/optimize/constants';
import Constants from 'relevant-shared/reportData/constants';
import { FormOf } from '../../Wrappers';
import MiscUtils from '../../../lib/miscUtils';
import CheckboxGroup from '../../CheckboxGroup';
import TextField from '../../TextField';
import Select from '../../Select';
import DateUtils from '../../../lib/dateUtils';
import Switch from '../../Switch';

const {
	FILTER_FLAG_SUMS,
	cpKey,
	cpLabel,
} = HbCustomizer;

const isValueCompatible = Object.fromEntries(VALUE_REPORT_COMPATIBLE_DIMENSIONS.map((k) => [k, true]));

class FrontendHbCustomizer extends HbCustomizer {
	splitMetricsToUiGroups(metrics, { groupBy, whereIn }) {
		const optimizeMetrics = _.pickBy(metrics, (v, k) => isOptiMetric(k));
		const normalMetrics = _.pickBy(metrics, (v, k) => !optimizeMetrics[k]);
		const hasIncompatible = [...groupBy, ..._.keys(_.pickBy(whereIn, (arr) => arr.length))]
			.some((k) => !isValueCompatible[k]);
		return [
			{ metrics: normalMetrics },
			{
				metrics: !hasIncompatible && optimizeMetrics,
				title: (
					<>
						Optimization values
						<p style={{ color: 'gray', fontSize: 'small' }}>
							<i>
								Recent optimal values, limits available dimensions
							</i>
						</p>
					</>
				),
			},
		];
	}

	getDimensionExclude({ sums, groupBy }, groupByOptions) {
		const res = [];
		if (sums.some(isOptiMetric)) {
			res.push(..._.keys(groupByOptions).filter((k) => !isValueCompatible[k]));
		}
		const ONLY_ONE = ['floorRange', 'adsOptimized']; // Currently can't be combined :(
		const onlyOne = groupBy.find((d) => ONLY_ONE.includes(d));
		if (onlyOne) {
			res.push(..._.without(ONLY_ONE, onlyOne));
		}
		return _.uniq(res);
	}

	renderTopBar({ whereIn, timezone }, onStateChange) {
		const { __excludeFlags, demandChannel } = whereIn || {};
		const { rtStartDate, rtMightHaveData } = this.reportData.report.hbMeta?.rtInfo || {};
		if (!rtMightHaveData) {
			return null;
		}
		const headerBiddingOnly = _.isEqual(demandChannel, ['headerBidding']);
		const includeApproximations = !__excludeFlags?.includes('isApproximation');

		const makeDate = (d) => new Date(
			DateUtils.timezoneOffsetDate(d, timezone),
		).toISOString().substring(0, 16);

		return (
			<Box sx={{ display: 'flex' }}>
				<Box sx={{ textWrap: 'nowrap' }}>
					<Grid item xs={12}>
						<Switch
							label="Adserver approximations"
							value={includeApproximations}
							color="primary"
							onChange={() => onStateChange({
								whereIn: {
									...whereIn,
									__excludeFlags: [
										..._.without(__excludeFlags, 'isApproximation'),
										...(!includeApproximations ? [] : ['isApproximation']),
									],
								},
							})}
						/>
					</Grid>
					<Grid item xs={12}>
						<Switch
							label="Headerbidding only"
							value={headerBiddingOnly}
							color="primary"
							onChange={() => onStateChange({
								whereIn: {
									..._.omit(whereIn, 'demandChannel'),
									...(!headerBiddingOnly && { demandChannel: ['headerBidding'] }),
								},
							})}
							style={{ marginTop: -15 }}
						/>
					</Grid>
				</Box>
				{includeApproximations && !headerBiddingOnly && rtStartDate && (
					<Box sx={{ flexGrow: 1, width: '100%' }}>
						<Alert severity="info">
							Adserver RTB revenue approximated from:
							{' '}
							{makeDate(rtStartDate)}
							.
						</Alert>
					</Box>
				)}
			</Box>
		);
	}

	createFlagFilter({
		name, selectOptions, label, hasSelect,
	}) {
		const filterSelect = selectOptions(name);
		const isAnd = filterSelect.selected.includes('AND');
		const itemsWithFlag = (items, andFlag) => _.without(
			_.uniq([...items, ...(andFlag ? ['AND'] : [])]),
			!andFlag && 'AND',
		);
		const filterFlagsFilter = {
			label,
			name,
			forceShow: true,
			expandSelectorProps: { selected: itemsWithFlag(filterSelect.selected) },
			rendered: (
				<>
					{hasSelect && (
						<Select
							name="filterAndOr"
							label="Condition"
							items={['OR', 'AND'].map((value) => ({ label: value, value }))}
							onChange={(ev) => (
								filterSelect.onChange(itemsWithFlag(filterSelect.selected, ev.target.value === 'AND'))
							)}
							value={isAnd ? 'AND' : 'OR'}
							fullWidth
						/>
					)}
					<CheckboxGroup
						items={_.map(FILTER_FLAG_SUMS, (text, id) => ({ id, text }))}
						{...filterSelect}
						onChange={(items) => filterSelect.onChange(itemsWithFlag(items, isAnd))}
					/>
				</>
			),
		};
		return filterFlagsFilter;
	}

	getCustomFilters({ selectOptions }) {
		const { hbMeta } = this.reportData.report;
		const bidders = MiscUtils.alphaSorted(hbMeta.bidders, 'name');
		const bidderFilter = {
			label: 'Bidders',
			name: 'bidder',
			rendered: (
				<CheckboxGroup
					items={bidders.map((bidder) => ({ id: bidder.id, text: bidder.name }))}
					{...selectOptions('bidder')}
				/>
			),
		};
		const filterFlagsFilter = this.createFlagFilter({
			selectOptions,
			hasSelect: true,
			name: '__filterFlags',
			label: 'Filter bids by',
		});
		const exlcudeFlagsFilter = this.createFlagFilter({
			selectOptions,
			name: '__excludeFlags',
			label: 'Exclude bids by',
		});

		const customParams = MiscUtils.alphaSorted(hbMeta.customParams, 'name');
		const stringFilters = customParams.filter((c) => !c.isNumeric).map(({ name, values }) => ({
			label: cpLabel(name),
			name: `_${name}`,
			rendered: (
				<CheckboxGroup
					items={_.sortBy(values).map((v) => ({ id: v, text: v }))}
					{...selectOptions(cpKey(name))}
				/>
			),
		})).filter(({ name }) => !Constants.hb.GROUP_BY_OPTIONS[name]); // Don't double up "UI built-in" custom dims
		const numFilters = customParams.filter((c) => c.isNumeric).map(({ name }) => {
			const { selected, onChange } = selectOptions(cpKey(name));
			const model = _.isPlainObject(selected) ? selected : {};
			return {
				label: cpLabel(name),
				name: `_${name}`,
				forceShow: true,
				noExpander: true,
				rendered: (
					<FormOf
						key={name}
						model={model}
						onFieldUpdate={() => {
							const obj = {};
							_.forOwn(model, (v, k) => {
								const num = parseInt(v, 10);
								if (!isNaN(num)) {
									obj[k] = num;
								}
							});
							onChange(_.isEmpty(obj) ? undefined : obj);
						}}
						content={({ field }) => (
							<>
								<Grid item xs={4}>
									{cpLabel(name)}
									:
								</Grid>
								<Grid item xs={4}>
									<TextField float label="Min" {...field('min')} />
								</Grid>
								<Grid item xs={4}>
									<TextField float label="Max" {...field('max')} />
								</Grid>
							</>
						)}
					/>
				),
			};
		});
		return [
			bidderFilter,
			...(hbMeta.isSummaryReport ? [] : [filterFlagsFilter, exlcudeFlagsFilter]),
			...stringFilters,
			...numFilters,
		];
	}
}

export default FrontendHbCustomizer;
