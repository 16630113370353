import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AiState {
  isPinned: boolean;
  setIsPinned: (pinned: boolean) => void;
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
	pinnedWidth: number;
	setPinnedWidth: (width: number) => void;

	isPinnedAndOpen: boolean;
	sendMessage: (message: string, context?: object) => void;
	messageQueue: ({ message: string, context: object })[];
	setMessageQueue: (queue: ({ message: string, context: object })[]) => void;
}

export const useAiStore = create<AiState>()(
	persist(
		(set) => ({
			isPinned: false,
			setIsPinned: (pinned) => set((state) => ({
				isPinned: pinned,
				isPinnedAndOpen: state.isOpen && pinned,
			})),
			isOpen: false,
			setIsOpen: (open) => set((state) => ({
				isOpen: open,
				isPinnedAndOpen: state.isPinned && open,
			})),
			pinnedWidth: 500,
			setPinnedWidth: (width) => set(() => ({
				pinnedWidth: width,
			})),
			isPinnedAndOpen: false,
			sendMessage: (message, context) => {
				set((state) => ({
					messageQueue: [...state.messageQueue, { message, context }],
				}));
			},
			messageQueue: [],
			setMessageQueue: (queue) => set(() => ({
				messageQueue: [...queue],
			})),
		}),
		{
			name: 'ai-storage',
			partialize: (state) => ({
				isPinned: state.isPinned,
				isOpen: state.isOpen,
				pinnedWidth: state.pinnedWidth,
				isPinnedAndOpen: state.isPinnedAndOpen,
			}),
		},
	),
);
