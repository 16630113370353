import React, { Component, ErrorInfo, ReactNode } from 'react';
import { ReactComponent as DeadRobot } from 'assets/icons/DeadRobot.svg?component';
import { Button } from '../Button/Button';

interface Props {
	children: ReactNode;
	onReset?: () => void;
}

interface State {
	hasError: boolean;
	error: Error | null;
	errorInfo: ErrorInfo | null;
}

export class SmartsErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
		error: null,
		errorInfo: null,
	};

	public static getDerivedStateFromError(error: Error): State {
		return {
			hasError: true,
			error,
			errorInfo: null,
		};
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({
			error,
			errorInfo,
		});
	}

	private handleReset = () => {
		this.props.onReset?.();
		this.setState({
			hasError: false,
			error: null,
			errorInfo: null,
		});
	};

	public render() {
		if (this.state.hasError) {
			return (
				<div className="flex flex-col items-center justify-center h-full p-4 text-center bg-white backdrop-blur-sm rounded-lg">
					<DeadRobot className="size-20 text-cherry-600 -mt-16 rotate-12" />

					<h5 className="text-xl font-semibold mt-4 text-[#040E20]">
						Relevant AI encountered an error
					</h5>

					<p className="text-base text-grey-500 mt-1 px-4 leading-relaxed">
						Please try again, or contact support if the issue persists.
					</p>

					<Button
						className="mt-6"
						variant="outlined"
						onClick={this.handleReset}
					>
						Reload
					</Button>
				</div>
			);
		}

		return this.props.children;
	}
}
