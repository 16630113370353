import { HelpOutlineRounded, SendRounded } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from '@uidotdev/usehooks';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as DeadRobot } from '../../assets/icons/DeadRobot.svg?component';
import { useScrollState } from '../../hooks/useScrollState';
import { useVisibilityState } from '../../hooks/useVisibilityState';
import SystemData from '../../lib/systemData';
import { useAiStore } from '../../stores/AiStore';
import { Badge } from '../Badge';
import { Button } from '../Button/Button';
import { StyledTooltip } from '../StyledTooltip';
import { SmartsMarkdown } from './SmartsMarkdown';
import { aiApiCall } from './utils';
import { stores } from '../../stores';

type SmartHelpProps = {
	query: string;
	question: string;
	sources?: ('relevant' | 'prebid')[];
	answer?: string;
	side?: 'left' | 'right';
	visible?: boolean;
	label?: string;
	showFollowUp?: boolean;
	showFollowUpSuggestion?: boolean;
}

export function SmartHelp({
	query,
	question,
	sources,
	answer: initialAnswer,
	side: initialSide,
	visible: initialVisible,
	label,
	followUp: showFollowUp = true,
	showFollowUpSuggestion = false,
}: SmartHelpProps) {
	const [visible, setVisible, shouldRender] = useVisibilityState(!!initialVisible, 300);
	const [answer, setAnswer] = useState(initialAnswer);
	const [followUpSuggestion, setFollowUpSuggestion] = useState<string | undefined>(undefined);
	const { ref: answerRef, isBottom: isScrolledToBottom, isTop: isScrolledToTop } = useScrollState();
	const { setIsOpen: aiSetIsOpen, sendMessage: aiSendMessage } = useAiStore();
	const [language] = useLocalStorage<string>(`aiLang-${stores.identity.userId()}`, 'eng');
	const inputRef = useRef<HTMLInputElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const [showOnLeft, setShowOnLeft] = useState(initialSide === 'left');
	const followUpEnabled = showFollowUp && SystemData.genericData?.aiEnabled;

	const helpQuery = useQuery<{ key: string, text: string, followup?: string }>({
		queryKey: ['ai', 'help', query],
		queryFn: async () => {
			return await aiApiCall('aiHelp', { query, question, language, sources });
		},
		enabled: shouldRender,
		refetchOnWindowFocus: false,
		retry: false,
	});

	useEffect(() => {
		if (helpQuery.data) {
			setAnswer(helpQuery.data.text);
			setFollowUpSuggestion(helpQuery.data.followup);
		}
	}, [helpQuery.data]);

	// Calculate vertical position and pointer offset
	const calculateVerticalPosition = (buttonRect: DOMRect, containerRect: DOMRect, pointerHeight: number) => {
		// Initial position centered on button height (taking pointer corner into account)
		let popupTop = buttonRect.top - pointerHeight / 2 + buttonRect.height / 2;

		// Constrain to viewport and calculate pointer offset in one step
		popupTop = Math.max(0, Math.min(popupTop, window.innerHeight - containerRect.height));
		const pointerOffset = Math.min(
			Math.max(buttonRect.top - popupTop - pointerHeight / 2 + buttonRect.height / 2, 0),
			containerRect.height - pointerHeight,
		);

		return { popupTop, pointerOffset };
	};

	// Calculate horizontal position and which side to show on
	const calculateHorizontalPosition = (buttonRect: DOMRect, containerRect: DOMRect, preferredSide?: 'left' | 'right') => {
		const buttonMargin = 10;
		let popupLeft: number;
		let isOnLeft: boolean;

		if (preferredSide === 'left') {
			// Try left side first
			const spaceOnLeft = buttonRect.left - buttonMargin;
			isOnLeft = spaceOnLeft >= containerRect.width;
			popupLeft = isOnLeft
				? buttonRect.left - containerRect.width - buttonMargin
				: buttonRect.right + buttonMargin;
		} else {
			// Try right side first
			const spaceOnRight = window.innerWidth - buttonRect.right - buttonMargin;
			isOnLeft = !(spaceOnRight >= containerRect.width);
			popupLeft = !isOnLeft
				? buttonRect.right + buttonMargin
				: buttonRect.left - containerRect.width - buttonMargin;
		}

		return { popupLeft, isOnLeft };
	};

	// Update popup position
	const updatePopupPosition = useCallback(() => {
		if (!shouldRender || !buttonRef.current || !containerRef.current) {
			return;
		}

		const buttonRect = buttonRef.current.getBoundingClientRect();
		const containerRect = containerRef.current.getBoundingClientRect();
		const pointerStyle = window.getComputedStyle(containerRef.current, ':before');
		const pointerHeight = parseFloat(pointerStyle.height) + parseFloat(pointerStyle.marginTop) + parseFloat(pointerStyle.marginBottom);

		// Calculate vertical position and pointer offset
		const { popupTop, pointerOffset } = calculateVerticalPosition(buttonRect, containerRect, pointerHeight);

		// Calculate horizontal position and side
		const { popupLeft, isOnLeft } = calculateHorizontalPosition(buttonRect, containerRect, initialSide);
		setShowOnLeft(isOnLeft);

		// Apply position directly
		containerRef.current.style.top = `${popupTop}px`;
		containerRef.current.style.left = `${popupLeft}px`;
		containerRef.current.style.setProperty('--pointer-offset', `${pointerOffset}px`);
	}, [initialSide, shouldRender]);

	// Set up the position update mechanism
	useEffect(() => {
		if (!visible) {
			return () => {};
		}

		// Initial position update immediately
		updatePopupPosition();

		window.addEventListener('scroll', updatePopupPosition, true);
		window.addEventListener('resize', updatePopupPosition);

		return () => {
			window.removeEventListener('scroll', updatePopupPosition, true);
			window.removeEventListener('resize', updatePopupPosition);
		};
	}, [visible, updatePopupPosition]);

	// Close when clicking outside
	useEffect(() => {
		const handleClickOutside = (e: MouseEvent) => {
			// If the click is on the button, don't do anything
			if (buttonRef.current && buttonRef.current.contains(e.target as Node)) {
				return;
			}

			// If the click is outside the popup, close it
			if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
				setVisible(false);
			}
		};

		if (visible) {
			document.addEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [visible, setVisible]);

	const handleSend = (e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.stopPropagation();
		let message = inputRef.current?.value;
		if (!message && followUpEnabled && followUpSuggestion) {
			message = followUpSuggestion;
		}
		if (message) {
			const helpKey = helpQuery.data?.key;
			aiSetIsOpen(true);
			aiSendMessage(message, { helpKey });
			inputRef.current.value = '';
			setVisible(false);
		}
	};

	// Render popup content
	const renderPopupContent = () => {
		if (!shouldRender) return null;

		return createPortal(
			<div
				ref={containerRef}
				className={clsx(
					'fixed box-border transition-opacity duration-300 bg-white rounded-lg p-4 shadow-lg border border-gray-200 w-[450px] z-aiHelp',
					[
						showOnLeft
							? 'before:right-[-8px] before:left-auto before:rotate-[225deg]'
							: 'before:left-[-8px] before:rotate-45',
						'before:absolute before:top-[var(--pointer-offset)] before:w-4 before:h-4 before:my-3 before:bg-white',
						'before:border-b before:border-l before:border-gray-200 before:shadow-[-3px_3px_3px_rgba(0,0,0,0.1)]',
					],
					visible ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none',
				)}
				style={{
					top: '0px',
					left: '0px',
					// @ts-ignore - Custom CSS property for pointer positioning
					'--pointer-offset': `14px`,
				}}
			>
				{/* Error overlay */}
				{helpQuery.isError && (
					<div className={clsx(
						'absolute text-cherry-600 inset-0 flex flex-col items-center justify-center text-center backdrop-blur-sm bg-white/50 z-[1] animate-fade-in',
					)}>
						<DeadRobot className="size-20 text-cherry-600 -mt-16 rotate-12" />
						{/* <span className="font-medium text-lg">Ouch!</span> */}
						<p className="text-base text-grey-500 mt-4 px-4 leading-relaxed">
							Sorry, we failed to fetch the latest information.
							<br />
							You may have better luck finding it in our <span className="font-medium">manual</span> instead!
						</p>
						<Button
							variant="outlined"
							href={`https://help.relevant-digital.com/knowledge/kb-search-results?term=${query}`}
							className="mt-6"
						>
							Relevant Yield Manual
						</Button>
					</div>
				)}
				<div className="flex flex-col justify-between">
					<div className="relative">
						{/* Answer */}
						<div
							// @ts-ignore - Type mismatch between HTMLElement and HTMLDivElement
							ref={answerRef}
							className="text-sm text-grey-600 h-[400px] w-full overflow-y-auto pr-1 [mask-image:linear-gradient(to_bottom,black_calc(100%-2rem),transparent_100%)] block"
						>
							<Badge color="yellow">AI Help</Badge>
							{/* Skeleton while loading */}
							{!helpQuery.isSuccess && (
								<>
									<h1 className={clsx(
										'bg-grey-200 rounded-md text-lg text-transparent font-medium my-2',
										helpQuery.isFetching && 'animate-pulse',
									)}>
										Lorem ipsum
									</h1>
									{/* This has placeholder content cause it's the easiest way to create a multiline box that looks like text with gaps in between lines :) */}
									<span className={clsx(
										'inline bg-grey-200 rounded-sm text-transparent my-2',
										helpQuery.isFetching && 'animate-pulse',
									)}>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
									</span>
								</>
							)}
							<div className={clsx(
								'transition-opacity duration-500',
								helpQuery.isLoading ? 'opacity-0' : 'opacity-100',
							)}>
								<SmartsMarkdown>
									{answer}
								</SmartsMarkdown>
							</div>
						</div>

						{/* Fade-out gradient when overflowing */}
						<div
							className={clsx(
								'absolute top-0 left-0 right-0 h-16 bg-gradient-to-b from-white to-transparent pointer-events-none transition-opacity duration-100',
								isScrolledToTop && 'opacity-0',
							)}
						/>
						<div
							className={clsx(
								'absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none transition-opacity duration-100',
								isScrolledToBottom && 'opacity-0',
							)}
						/>
					</div>

					{/* Input */}
					{followUpEnabled && (
						<div className="pt-3 border-gray-200">
							<div className="relative">
								<form onSubmit={handleSend}>
									<input
										ref={inputRef}
										type="text"
										className="w-full rounded-md border border-gray-300 px-3 py-2 pr-10 text-gray-700 placeholder-gray-400 focus:border-black focus:placeholder-transparent focus:outline-none"
										placeholder={helpQuery.isSuccess ? ((showFollowUpSuggestion && followUpSuggestion) || 'Ask Relevant AI') : ''}
										disabled={!helpQuery.isSuccess}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												e.preventDefault();
												e.stopPropagation();
												handleSend(e);
											}
										}}
									/>
									<button
										type="submit"
										className="absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer"
										disabled={!helpQuery.isSuccess}
									>
										<SendRounded className="text-blue-500 hover:text-blue-600" />
									</button>
								</form>
							</div>
						</div>
					)}
				</div>
			</div>,
			document.body,
		);
	};

	return (
		<div className="inline-flex relative">
			{shouldRender && renderPopupContent()}

			<StyledTooltip title="Documentation">
				<button
					ref={buttonRef}
					type="button"
					onClick={() => {
						setVisible(!visible);
						// Re-fetch on click in case pre-fetching fails for some reason
						if (!helpQuery.isSuccess) {
							helpQuery.refetch();
						}
					}}
					onMouseOver={() => {
						// Pre-fetch on hover to make it feel more responsive
						if (!helpQuery.isFetched) {
							helpQuery.refetch();
						}
					}}
					onFocus={() => {
						if (!helpQuery.isFetched) {
							helpQuery.refetch();
						}
					}}
					className={clsx(
						'inline-flex border border-transparent transition-all items-center justify-center gap-1',
						label ? 'p-1 rounded' : 'rounded-full',
						'text-grey-500 hover:text-grey-600 hover:bg-cherry-100 active:text-cherry-600 active:border-cherry-600 disabled:text-grey-450',
					)}
				>
					<HelpOutlineRounded />
					{label}
				</button>
			</StyledTooltip>
		</div>
	);
}
