import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license-pro';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
	createTheme,
	ThemeProvider,
	StyledEngineProvider,
} from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import MomentAdapter from '@date-io/moment';
import { green } from '@mui/material/colors';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import config from 'appconfig'; // eslint-disable-line import/no-unresolved
import suppressWarnings from './lib/suppressWarnings';
import Base from './layouts/Base';
import './assets/Relevant_logoicon.png';
import './initImports';

const defaultBaseSpacing = 8;
const defaultSpacing = (factor) => factor * defaultBaseSpacing;

const spacingWithOffset = (factor, offset) => defaultSpacing(factor) + offset;
// Must be included somewhere in the code, and why not here..?
require('./components/Report/frontendReportInterface');

LicenseInfo.setLicenseKey('84b1dc0de7531a63e5ff17c97d00d6b9Tz0xMDgwOTYsRT0xNzcxNDU5MTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=');

const queryClient = new QueryClient();

if (config.debugMode && !window.localStorage.noSuppressWarnings) {
	suppressWarnings();
}

// The 'watered' variants is same color but with opacity,
// e.g wateredLight is 'info.light' with opacity.
const theme = createTheme({
	palette: {
		common: {
			black: '#333333',
			white: '#FFFFFF',
		},
		// Disabled color to use on dark backgrounds
		// Same as mui defaults https://mui.com/customization/dark-mode/
		disabledDark: {
			main: 'rgba(255, 255, 255, 0.3)',
		},
		default: {
			black: '#333333',
			white: '#FFFFFF',
		},
		primary: {
			main: '#262F3D',
			dark: '#000517',
			light: '#4f5867',
			watered: 'rgba(38, 47, 61, 0.8)',
			contrastText: '#FFFFFF',
			tonedDownText: '#b9c1c9',
		},
		secondary: {
			main: '#EE3642',
			// Same color with opacity
			watered: 'rgba(238, 54, 66,0.8)',
			contrastText: '#FFFFFF',
		},
		offWhite: {
			main: '#F8F8F8',
		},
		text: {
			main: '#313233',
			light: '#FFFFFF',
			primary: '#313233',
			secondary: 'rgba(0, 0, 0, 0.6)',
			sub: 'rgba(0, 0, 0, 0.38)',
			white: '#FFFFFF',
		},
		link: {
			main: '#FF4F0F',
		},
		success: {
			main: green[500],
			light: green[300],
			dark: green[700],
			wateredLight: 'rgba(129, 199, 132, 0.8)',
		},
		disabled: {
			main: 'rgba(0, 0, 0, 0.26)', // Same as mui default
		},
		// Same as mui defaults
		error: {
			contrastText: '#fff',
			dark: '#c62828',
			light: '#ef5350',
			main: '#d32f2f',
			wateredMain: 'rgba(211, 47, 47, 0.8)',
		},
		warning: {
			main: '#ffa726',
			light: '#ffb74d',
		},
		// Same as mui defaults
		info: {
			contrastText: '#fff',
			main: '#0288d1',
			light: '#03a9f4',
			dark: '#01579b',
			wateredLight: 'rgb(3, 169, 244, 0.8)',
			veryWateredLight: 'rgb(3, 169, 244, 0.4)',
		},
	},
	typography: {
		fontFamily: 'Roboto, sans-serif',
		h1: {
			fontSize: '22px',
			fontWeight: 400,
			lineHeight: 1.9,
		},
		h2: {
			fontSize: '20px',
			fontWeight: 300,
			lineHeight: 1.8,
		},
		h3: {
			fontSize: '18px',
			fontWeight: 300,
			lineHeight: 1.5,
		},
		h4: {
			fontSize: '16px',
			fontWeight: 300,
			lineHeight: 1.3,
		},
		h5: {
			fontSize: '16px',
			fontWeight: 300,
			lineHeight: 1.2,
		},
		h6: {
			fontSize: '18px',
			fontWeight: 400,
			lineHeight: 1.5,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
				},
			},
		},
	},
	spacing: defaultSpacing, // same as mui default, just want to be able to access it in spacingWithOffset,
	spacingWithOffset,
});

@observer
class App extends Component {
	render() {
		return (
			<QueryClientProvider client={queryClient}>
				<LocalizationProvider dateAdapter={MomentAdapter} locale="en">
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={theme}>
							<CssBaseline />
							<BrowserRouter>
								<Base />
							</BrowserRouter>
						</ThemeProvider>
					</StyledEngineProvider>
				</LocalizationProvider>
			</QueryClientProvider>
		);
	}
}

export default App;
