/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import _ from 'lodash';
import { BidderNode } from 'relevant-shared/objects/pubTree';
import { PREBID_CONFIGURATION_DIM } from 'relevant-shared/misc/sharedConstants';
import Typography from '@mui/material/Typography';
import PopupSelector from '../PopupSelector';
import FieldArray from '../FieldArray';
import SystemData from '../../lib/systemData';
import { Cdn } from '../../api/relevant';
import TreeSelect from '../TreeSelect';
import BidParamEdit from '../BidParamEdit';
import { CheckboxSign, withUpdate } from '../Wrappers';
import PrebidConfigEditForm from './PrebidConfigEditForm';
import PrebidConfigChildTable from './PrebidConfigChildTable';
import FormContext from '../FormContext';
import ExpandSelector from '../ExpandSelector';
import Report from '../Report';
import InlineBidParamContainer from '../BidParamEdit/inlineBidParamContainer';
import { filterEnabledForCfg } from './utils';
import { Button } from '../Button/Button';

const getNodeChildren = (n) => {
	if (n.type === 'PublisherNode' || n.type === 'SiteNode') {
		return n.children.filter((c) => c.bidderNodes.length);
	} if (n.type === 'PlacementNode') {
		return n.children.filter((c) => c.isPbjsBidder);
	}
	return n.children;
};

@withUpdate
class PrebidConfigEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderPbjsConfig({
		fld, pbjsConfig, update, dirtyBidParamUpdater,
	}) {
		const {
			site, publisher, form, systems, publisherNode, parent,
		} = this.props;
		const treeConfig = parent || pbjsConfig;
		const { cdn } = this.state;
		const paramsByNode = _.keyBy(publisher.bidParams.filter((u) => (
			u.pbCfgId === pbjsConfig.id && !_.isEmpty(u.params)
		)), 'unitId');
		const childHasBidParams = (n) => getNodeChildren(n).find((c) => paramsByNode[c.id] || childHasBidParams(c));

		const tag = `
<script async src="https://${cdn.cdnDomain}/static/tags/${site.id}.js"></script>
<script>
	(function() {
		window.relevantDigital = window.relevantDigital || {};
		relevantDigital.cmd = relevantDigital.cmd || [];
		relevantDigital.cmd.push(function() {
			relevantDigital.loadPrebid({
				configId: '${pbjsConfig.id}',
				manageAdserver: true,
				collapseEmptyDivs: true,
				collapseBeforeAdFetch: false,
				allowedDivIds: null, // set to an array to only load certain <div>s, example - ["divId1", "divId2"]
				noSlotReload: false, // set true to only load ad slots that have never been loaded 
			});
		});
	})();
</script>`;
		const renderReport = ({ expProps, reportProps }) => (
			<ExpandSelector
				paperProps={{ square: true, elevation: 0 }}
				{...expProps}
			>
				<Report
					type="hb"
					title=""
					cardProps={{ square: true, elevation: 0 }}
					start={-1}
					end={0}
					groupBy={[PREBID_CONFIGURATION_DIM]}
					whereIn={{
						[PREBID_CONFIGURATION_DIM]: [
							pbjsConfig.id, ...pbjsConfig.prebidConfigs.map((p) => p.id)],
					}}
					sums={['revenue']}
					{...reportProps}
				/>
			</ExpandSelector>
		);

		return (
			<Grid container spacing={3}>
				<PrebidConfigEditForm
					tag={tag}
					fld={fld}
					parent={parent}
					publisher={publisher}
					pbjsConfig={pbjsConfig}
					form={form}
					systems={systems}
					publisherNode={publisherNode}
					treeConfig={treeConfig}
					dirtyBidParamUpdater={dirtyBidParamUpdater}
				/>
				<Grid item xs={12}>
					<TreeSelect
						getChildren={getNodeChildren}
						getNodeId={(n) => n.id}
						getIsLeaf={(n) => n instanceof BidderNode}
						rootNode={publisherNode}
						readOnlyCheckbox={!!parent}
						selectedObjects={publisherNode.bidderNodesByConfig(treeConfig)}
						expandOnce={[publisherNode]}
						onSelectionChange={(nodes) => update(() => {
							publisherNode.updateBidderNodesForConfig(nodes, pbjsConfig);
						})}
						getLabel={(n) => (n.ssp ? `${n.ssp.name} - ${n.name}` : n.name)}
						render={(n) => {
							const { ssp } = n;
							let { name } = n;
							if (ssp) {
								name = (
									<span>
										<span style={{ fontWeight: 'bold' }}>{ssp.name}</span>
										{' '}
										-
										{n.name}
									</span>
								);
							}
							let statColor = 'rgba(0,0,0,0)';
							if (paramsByNode[n.id]) {
								statColor = 'green';
							} else if (childHasBidParams(n)) {
								statColor = 'lightgreen';
							}
							return (
								<Box display="flex">
									{name}
									&nbsp;&nbsp;
									<BidParamEdit
										form={form}
										pbjsConfig={pbjsConfig}
										node={n}
										dstObj={publisher}
										onDone={update}
										ssps={systems.ssps}
										userIdModules={(n.type !== 'SspPlacementNode' && n.type !== 'PlacementNode')
											? systems.userIdModules : []}
										dirtyBidParamSet={dirtyBidParamUpdater.dirtyBidParamSet}
									/>
									<CheckboxSign style={{ color: statColor }} />
								</Box>
							);
						}}
					/>
					{!parent && (
						<>
							{filterEnabledForCfg(pbjsConfig, true) && (
								<Alert severity="info">
									<AlertTitle>Info</AlertTitle>
									When using country targeting on active child configurations the total
									percentage of the child configurations can exceed 100% and the percentage
									left for the main configuration isn't shown.
								</Alert>
							)}
							<PrebidConfigChildTable
								prebidConfig={pbjsConfig}
								title="A/B Test and country targeting"
								props={this.props}
								field={fld}
							/>
						</>
					)}
				</Grid>
				{!parent && !!pbjsConfig.prebidConfigs?.length && (
					<Grid item xs={12}>
						<Typography
							sx={{ flex: '1 1 100%' }}
							variant="h6"
							id="tableTitle"
							component="div"
						>
							A/B Test Statistics
						</Typography>
						{renderReport({
							expProps: { title: 'Ad unit eCPM per prebid config' },
							reportProps: { sums: ['adUnitEcpm'] },
						})}
						{!!SystemData.genericData.hasHbSummaryAdsData && renderReport({
							expProps: { title: 'AdServer eCPM per prebid config yesterday' },
							reportProps: {
								type: 'summary_hb',
								sums: ['adsRevEcpm'],
								start: -1,
								end: -1,
							},
						})}
					</Grid>
				)}
				<Grid item xs={12}>
					<Typography
						variant="h6"
					>
						Configuration data
					</Typography>
					<BidParamEdit
						form={form}
						node={publisherNode.byId[pbjsConfig.id]}
						dstObj={publisher}
						onDone={() => form.update()}
						ssps={[]}
						containerCls={InlineBidParamContainer}
						elementContainerCls={InlineBidParamContainer}
						includeDataFilterFn={({ id }) => id === 'genericPbConfigData'}
						autoApplyChanges
						noEmptyExpandCustomParams
						dirtyBidParamSet={dirtyBidParamUpdater.dirtyBidParamSet}
					/>
				</Grid>
			</Grid>
		);
	}

	renderFieldArray(popup, dirtyBidParamUpdater) {
		const {
			site, form, field, parent, autoExpandItem, publisherNode,
		} = this.props;
		return (
			<FieldArray
				update={() => popup.formUpdate()}
				model={site}
				form={form}
				shouldAutoExpand={(item) => item === autoExpandItem}
				expandableElements
				expandStyle={{ padding: '0px 10px' }}
				confirmDelete="Do you want to delete this prebid configuration?"
				onDeleted={(obj) => (
					publisherNode.byId[obj.id]?.removeNode()
				)}
				afterHeader={(__, { name }) => name || 'No name'}
				render={(fld, pbjsConfig) => (
					<FormContext
						form={form}
						content={() => this.renderPbjsConfig({
							fld,
							pbjsConfig,
							update: (fn) => popup.formUpdate(fn),
							dirtyBidParamUpdater,
						})}
					/>
				)}
				name="prebidConfigs"
				field={field}
				noAutoAddRemove
				createNew={() => {
					const name = parent
						? `${parent.name} Child ${parent.prebidConfigs.length + 1}`
						: 'New prebid configuration';
					publisherNode.byId[site.id].createPrebidConfig({ name });
				}}
			/>
		);
	}

	render() {
		const {
			form, parent, useButtonStyle, title,
		} = this.props;
		return (
			(!(parent instanceof SystemData.models.PrebidConfigChild) && (
				<PrebidConfigEdit.PrebidConfigOpenListener.Consumer>
					{(listener) => (
						<PopupSelector
							form={form}
							fn={async () => this.setState({ cdn: await Cdn.listOne() })}
							customLink={!useButtonStyle ? undefined : ((openFn) => (
								<Button
									className="h-[56px] ml-1"
									onClick={openFn}
								>
									{title}
								</Button>
							))}
							title={title}
							size="md"
							onStateUpdate={({ expanded }) => {
								if (listener && expanded) {
									listener(this);
								}
							}}
							content={(popup) => this.renderFieldArray(popup, listener)}
						/>
					)}
				</PrebidConfigEdit.PrebidConfigOpenListener.Consumer>
			))
		);
	}
}

PrebidConfigEdit.propTypes = {
	publisher: PropTypes.object.isRequired,
	site: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	customLink: PropTypes.func,
	systems: PropTypes.object.isRequired,
	publisherNode: PropTypes.object.isRequired,
	parent: PropTypes.object,
	selected: PropTypes.object,
	autoExpandItem: PropTypes.object,
	useButtonStyle: PropTypes.bool,
	title: PropTypes.string,
};

PrebidConfigEdit.defaultProps = {
	parent: null,
	useButtonStyle: false,
	title: 'Prebid configurations',
	selected: null,
	customLink: null,
	autoExpandItem: null,
};

PrebidConfigEdit.PrebidConfigOpenListener = React.createContext(null);

export default PrebidConfigEdit;
