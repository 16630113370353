import _ from 'lodash';
import PrebidConfigNode from 'relevant-shared/objects/nodes/prebidConfigNode';

class RenderContext {
	constructor(settings) {
		const { props } = settings.editor;
		const { node, pbjsConfig } = props;
		Object.assign(this, { ...settings, node, pbjsConfig });
		['ssps', 'adservers'].forEach((k) => {
			// eslint-disable-next-line react/destructuring-assignment
			this[k] = node?.publisherNode[k] || props[k] || [];
		});
	}

	// Returns 'genericPbConfigData' fields with meta information (withMeta: true)
	pbConfigFields() {
		const { pbjsConfig, node, editor, fieldParams } = this;
		// Include possible not-yet-saved changes in open prebid configs
		const editors = [
			editor,
			..._.sortBy(_.toArray(editor.props.dirtyBidParamSet).filter(
				(d) => d.props.node instanceof PrebidConfigNode && d.props.node !== node,
			), (d) => (d.isChildConfig ? -1 : 1)),
		];
		const res = _.find(editors.map((e) => e.byObjectParams.genericPbConfigData?.fieldObj));
		if (res) { // If we have "live data" (prebid config fields are edited in the same BidParamEdit), use that
			return res;
		}
		const checkNode = node?.publisherNode.byId[pbjsConfig?.id] || node; // If we have a prebid config, use that first
		if (!checkNode) {
			return fieldParams('genericPbConfigData');
		}
		return checkNode.bidderParams({ sspId: 'genericPbConfigData', withMeta: true });
	}
}

export default RenderContext;
