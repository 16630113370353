/* eslint-disable prefer-rest-params */
const _ = require('lodash');

// TODO: let's get to the bottom of the problem below, some time..
const TO_SUPPRESS = [
	['Warning: `value` prop on `%s` should not be null', 'input'],
	'Warning: A component is changing an uncontrolled input to be controlled.',
	'Warning: A component is changing a controlled input to be uncontrolled.',
	/^Warning: .*?: Support for defaultProps will be removed from function components/,
	'Blocked aria-hidden on an element because its descendant retained focus',
];

const LEVELS = [
	'warn',
	'error',
];

export default function () {
	const levels = LEVELS.map((name) => [name, console[name]]);
	levels.forEach(([name, orgFn]) => {
		console[name] = function () {
			const matches = (suppress) => {
				if (suppress instanceof RegExp) {
					return suppress.test(arguments[0]);
				}
				const arr = _.isString(suppress) ? [suppress] : suppress;
				if (!arguments[0]?.startsWith?.(arr[0])) {
					return false;
				}
				for (let i = 1; i < arr.length; i += 1) {
					if (arguments[i] !== arr[i]) {
						return false;
					}
				}
				return true;
			};
			if (!TO_SUPPRESS.find((suppress) => matches(suppress))) {
				orgFn.apply(this, arguments);
			}
		};
	});
}
