/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	withRouter, Route,
} from 'react-router-dom';
import _ from 'lodash';
import config from 'appconfig'; // eslint-disable-line import/no-unresolved
import '../styles/base.css';
import { LoginPage } from '../pages/LoginPage/LoginPage';
import SystemData from '../lib/systemData';
import Public from './Public';
import { stores } from '../stores';
import GhostingHeader from '../components/GhostingHeader';
import { GenericReports } from '../pages/SimplePages';
import OperationWrapper from '../components/OperationWrapper';
import ghostingStyles from '../components/GhostingHeader/styles.css';
import BrowserUtils from '../lib/browserUtils';
import { Scope } from '../components/Wrappers';
import { ToastProvider } from '../providers/ToastProvider/ToastProvider';
import Main from './Main';
import Page from '../containers/Page';
import UserPage from '../pages/User';
import classes from '../api/classes';

const { apiClassMap } = classes;

class GlobalRenderContainer extends React.Component {
	render() {
		GlobalRenderContainer.lastInstance = this;
		return (
			<div>
				{GlobalRenderContainer.fns.map((obj) => {
					// Only call the render-functions once (avoids unnecessary re-renders)
					obj.renderRes = obj.renderRes || obj.fn((result) => {
						obj.resolve(result);
						_.pull(GlobalRenderContainer.fns, obj);
						GlobalRenderContainer.lastInstance.forceUpdate();
					});
					return (
						<div key={obj.key}>
							{obj.renderRes}
						</div>
					);
				})}
			</div>
		);
	}
}

GlobalRenderContainer.fns = [];

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.refreshKey = Math.random().toString();
	}

	initSystemSettings() {
		const { pageTitle, favicon } = SystemData.genericData.systemSettings;

		if (pageTitle) {
			document.title = pageTitle;
		}
		const link = document.createElement('link');
		link.rel = 'icon';
		if (favicon) {
			link.type = 'image/x-icon';
			link.href = favicon;
		} else {
			link.type = 'image/png';
			link.href = '/src/assets/Relevant_logoicon.png';
		}
		const head = document.getElementsByTagName('head')[0];
		head.appendChild(link);
	}

	loadDemoTrackers() {
		if (Layout.demoTrackersLoaded) {
			return;
		}
		Layout.demoTrackersLoaded = true;
		window.cX = window.cX || { callQueue: [] };
		const { cX } = window;
		cX.callQueue.push(['setSiteId', '1137417633504060936']);
		cX.callQueue.push(['sendPageViewEvent']);
		BrowserUtils.loadScript({ src: 'https://scdn.cxense.com/cx.js' });
		BrowserUtils.loadScript({
			src: 'https://js.hs-scripts.com/4674099.js',
			attribs: { id: 'hs-script-loader' },
		});
	}

	registerInStartDeliver(path) {
		if (!window.startdeliver) {
			const API_KEY = 'usgpub-a59074f69dbb49d5abceda8b2dac6116';
			window.startdeliver = (...args) => {
				window.startdeliver.q = window.startdeliver.q || [['initialize', API_KEY]];
				window.startdeliver.q.push(args);
			};
			BrowserUtils.loadScript({ src: 'https://startde.live/r.js' });
		}
		const adjusted = path?.replaceAll?.(/[a-f0-9]{24}/g, '[ID]');
		const userId = config.apis.relevant.replace('http://', '').replace('https://', '').replace('/', '');
		const emailOk = () => {
			const FORBIDDEN_END = ['@mugs.info', '@relevant-digital.com', '@relevant.fi'];
			const email = (stores.identity.email() || '').toLowerCase().trim();
			return !!email && !FORBIDDEN_END.some((s) => email?.endsWith(s));
		};
		if (adjusted !== undefined
			&& emailOk()
			&& !stores.identity.isGhosting()
			&& !config.debugMode
			&& Layout.startDeliverLastPath !== path
		) {
			window.startdeliver?.('sendEvent', {
				userId,
				usageType: adjusted || '/',
			});
			Layout.startDeliverLastPath = path;
		}
	}

	loadCustomFrontendCode() {
		if (Layout.customFrontendJsLoaded) {
			return;
		}
		Layout.customFrontendJsLoaded = true;
		const {
			customFrontendJs,
			customFrontendAttribs: attribs,
			hasFrontendListener,
		} = SystemData.genericData.systemSettings;
		if (!customFrontendJs) {
			return;
		}
		BrowserUtils.loadScript({ src: customFrontendJs, attribs });
		if (hasFrontendListener) {
			let pending = [];
			const object = {
				register(listeners) {
					const toRun = pending;
					pending = null;
					Object.assign(this, listeners);
					toRun.forEach(({ prop, args }) => this[prop]?.(...args));
				},
			};
			window.customFrontendListeners = new Proxy(object, {
				get(obj, prop) {
					return prop in obj ? obj[prop] : (...args) => pending?.push({ prop, args });
				},
			});
		}
	}

	async loadGenericData(op, { pathname, search }) {
		try {
			await SystemData.loadGenericData();
		} catch (e) {
			if (e.userHasNonExistingId) { // User has been re-created or different instances are tested locally
				stores.identity.logout();
				window.location = BrowserUtils.makeQs('/login', { redirect: `${pathname}${search}` });
				return new Promise(() => {}); // delay forever, this line is probably never reached
			}
			throw e;
		}
		if (SystemData.genericData.inDemoMode) {
			this.loadDemoTrackers();
		}
		this.loadCustomFrontendCode();
		this.initSystemSettings();
		SystemData.onDataInvalidated.add(() => op.reload(async () => {
			await SystemData.loadGenericData();
			Object.values(apiClassMap).forEach((cls) => cls.onAfterSystemDataInvalidated?.());
		}), true);
		return null;
	}

	render() {
		Layout.lastInstance = this;
		// Use refreshKey in <Switch /> in order to force a re-render when we're changing url query param.
		// For example, if you're on route /settings/tag_data_edit?type=XXXXXX&accountId=12345
		// And go to /settings/tag-data-edit?type=programmaticMasterTag
		const currLoc = { pathname: this.props.location.pathname, search: this.props.location.search };
		if (this.lastLoc && this.lastLoc.pathname === currLoc.pathname && this.lastLoc.search !== currLoc.search) {
			this.refreshKey = Math.random().toString();
		}
		this.lastLoc = currLoc;
		this.registerInStartDeliver(currLoc.pathname);

		return (
			<ToastProvider>
				<OperationWrapper
					fn={(op) => this.loadGenericData(op, currLoc)}
					content={() => (
						<Layout.GlobalComponents.Provider value={{}}>
							<div className="bg-neutral-page">
								{stores.identity.isGhosting() ? <GhostingHeader /> : null}
								{!!stores.identity.isDemoUser() && (
									<div className={`${ghostingStyles.header} ${ghostingStyles.demoHeader}`}>
										<p>Demo Only</p>
									</div>
								)}
								<GlobalRenderContainer />
								<Route exact path="/login" component={LoginPage} />
								<Route path="/public" component={Public} />
								<Main key={this.refreshKey}>
									<Route
										exact
										path="/user"
										render={() => (
											<Page title="User settings">
												<UserPage />
											</Page>
										)}
									/>
									<Route
										path="/generic-reports"
										render={() => (
											<Page>
												<GenericReports />
											</Page>
										)}
									/>
								</Main>
							</div>
						</Layout.GlobalComponents.Provider>
					)}
				/>
			</ToastProvider>
		);
	}
}

Layout.renderGlobal = (fn) => {
	let resolve;
	const promise = new Promise((r) => { resolve = r; });
	GlobalRenderContainer.fns.push({ fn, key: Math.random().toString(), resolve });
	if (GlobalRenderContainer.lastInstance) {
		GlobalRenderContainer.lastInstance.forceUpdate();
	}
	return promise;
};

Layout.GlobalComponents = React.createContext({});

Layout.GlobalComponent = function ({ name, content }) {
	return (
		<Scope
			content={(scope) => (
				<Layout.GlobalComponents.Consumer>
					{(globalComponents) => {
						globalComponents[name] = scope;
						return content(scope);
					}}
				</Layout.GlobalComponents.Consumer>
			)}
		/>
	);
};

Layout.GlobalComponent.propTypes = {
	name: PropTypes.string.isRequired,
	content: PropTypes.func.isRequired,
};

// The use of withRouter here is to force the Layout component to re-render whenever
// the location changes. react-router v4 used setState internally in BrowserRouter
// which would indirectly cause a re-render of its children (Layout, amongst others).
// Since very few components that use data from any of the stores actually subscribe
// to the corresponding updates (via e.g. @storeContainer, @withIdentity), we're
// forced to rely on this side effect which is the result of an implementation
// detail in react-router. Ergo recreating/mimicking it is necessary.
export default withRouter(Layout);
