import React from 'react';
import _ from 'lodash';
import Alert from '@mui/material/Alert';
import { PrebidDataObjects } from 'relevant-shared/prebid/prebidDataObjects.js';

const renderBeforeDisableBidder = ({ renderContext }) => {
	const { obj } = renderContext;
	const { disableFromHb } = obj;
	const pbConfigFields = renderContext.pbConfigFields();
	const settings = _.get(pbConfigFields, ['rlvBySsp', 'value', obj.id, 'value']);
	const disableReasons = _.pickBy({
		hasCountry: ['filterType', 'countries'].every((fld) => !_.isEmpty(settings?.[fld]?.value)) && (
			<>
				Disabled in some countries by&nbsp;
				<b>{pbConfigFields.rlvBySsp.description}</b>&nbsp;
				settings under&nbsp;
				<b>{PrebidDataObjects.genericPbConfigData.name}</b>
			</>
		),
		disableFromHb: disableFromHb && (
			<>
				This bidder is disabled from header bidding in the SSP settings
			</>
		),
	});
	if (_.isEmpty(disableReasons)) {
		return null;
	}
	return (
		<>
			{_.entries(disableReasons).map(([key, elm]) => (
				<Alert key={key} severity="info">
					{elm}
				</Alert>
			))}
		</>
	);
};

export default {
	renderBeforeDisableBidder,
};
