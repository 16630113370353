/* eslint-disable react/no-unstable-nested-components */
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef } from 'react';
import RelevantLogo from '../../assets/Relevant_logoicon.png';
import { useVisibilityState } from '../../hooks/useVisibilityState';
import { useAiStore } from '../../stores/AiStore';
import { SmartsContent } from './SmartsContent';
import { SmartsErrorBoundary } from './SmartsErrorBoundary';
import { stores } from '../../stores';

export function Smarts() {
	const { isPinned, setIsPinned, isOpen, setIsOpen, pinnedWidth, setPinnedWidth } = useAiStore();
	const [visible, setVisible, shouldRender] = useVisibilityState(isOpen, 300);
	const input = useRef<HTMLInputElement>(null);

	const handleSetVisible = useCallback((state: boolean) => {
		setVisible(state);
		setIsOpen(state);
	}, [setIsOpen, setVisible]);

	// Ensure visibility state stays in sync with isOpen from AiStore
	useEffect(() => {
		setVisible(isOpen);
	}, [isOpen, setVisible]);

	useEffect(() => {
		if (visible) {
			input.current?.focus();
		}
	}, [visible, input]);

	// Resize in pinned mode
	const aiPanelRef = useRef<HTMLDivElement>(null);
	const handleMouseDown = useCallback((e: React.MouseEvent) => {
		if (e.button !== 0) {
			return;
		}
		e.preventDefault();
		const startX = e.clientX;
		const startWidth = pinnedWidth;

		if (!aiPanelRef.current) {
			return;
		}

		const mainPlaceholder = document.getElementById('main-ai-placeholder');
		// Disable transitions while dragging
		aiPanelRef.current!.style.transitionProperty = 'none';
		if (mainPlaceholder) {
			mainPlaceholder.style.transitionProperty = 'none';
		}

		const handle = e.target as HTMLElement;
		handle.style.setProperty('--ai-splitter-opacity', '1');

		let rafId: number | null = null;
		const handleMouseMove = (moveEvent: MouseEvent) => {
			if (rafId) {
				cancelAnimationFrame(rafId);
			}

			rafId = requestAnimationFrame(() => {
				const delta = startX - moveEvent.clientX;
				const newWidth = Math.max(300, Math.min(1500, startWidth + delta));
				// Update both the AI panel and the main placeholder
				aiPanelRef.current.style.setProperty('--ai-width', `${newWidth}px`);
				mainPlaceholder.style.setProperty('--ai-width', `${newWidth}px`);
			});
		};

		const handleMouseUp = () => {
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
			if (rafId) {
				cancelAnimationFrame(rafId);
			}

			const currentWidth = parseInt(getComputedStyle(aiPanelRef.current!)
				.getPropertyValue('--ai-width'), 10);
			setPinnedWidth(currentWidth);

			// Re-enable transitions
			aiPanelRef.current!.style.transitionProperty = '';
			if (mainPlaceholder) {
				mainPlaceholder.style.transitionProperty = '';
			}

			handle.style.setProperty('--ai-splitter-opacity', '0');
		};

		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
	}, [pinnedWidth, setPinnedWidth]);

	const handleReset = () => {
		// Clear current thread id in case there was something in it that caused the error
		localStorage.removeItem(`aiThread-${stores.identity.userId()}`);
	};

	return (
		<>
			{/* Show/hide button */}
			<div
				className={clsx(
					'fixed bottom-0 right-0 z-aiButton transition-all duration-300 ease-in-out w-[48px] h-[48px] mr-4 mb-4 overflow-visible',
					'hover:w-[175px] rounded-full group',
					'bg-gradient-to-r from-[#040E20] to-blue-400',
					// 'bg-red-600',
					visible ? 'opacity-0' : 'opacity-100',
				)}
				onClick={() => handleSetVisible(true)}
				onKeyDown={(e) => e.key === 'Enter' && handleSetVisible(true)}
				tabIndex={0}
				role="button"
				inert={visible ? '' : undefined}
			>
				<div className="absolute z-[1] w-[48px] h-[48px] rounded-full bg-red-500 flex items-center justify-center cursor-pointer flex-shrink-0">
					<img src={RelevantLogo} alt="Relevant Logo" className="w-8 h-8" />
				</div>
				<div className="w-full h-full p-[2px]">
					<div className="bg-white rounded-full flex items-center w-full h-full overflow-hidden">
						<div className="flex items-center -translate-x-full group-hover:translate-x-0 transition-all duration-300 ease-in-out ml-[58px]">
							<span className="font-semibold text-lg flex-shrink-0 bg-gradient-to-r from-blue-950 to-blue-400 text-transparent bg-clip-text">Relevant AI</span>
						</div>
					</div>
				</div>
			</div>

			{/* Chat container */}
			{shouldRender && (
				<div
					className={clsx(
						'fixed left-0 bottom-0 right-0 z-aiWindow flex flex-col items-end pointer-events-none',
						'top-16', // Compensate for the top bar
					)}
				>
					<div className="flex-1" />
					<div
						className={clsx(
							'flex-initial border-gray-200 border box-border',
							'transition-all duration-300 ease-in-out',
							'flex flex-col bg-white relative pointer-events-auto',
							isPinned ? 'h-full' : 'h-[700px] m-4 rounded-lg',
							visible
								? 'opacity-100 shadow-lg'
								: 'opacity-0 translate-y-6',
						)}
						style={{
							width: isPinned ? `var(--ai-width)` : '600px',
							['--ai-width' as string]: `${pinnedWidth}px`,
						}}
						ref={aiPanelRef}
						inert={!visible ? '' : undefined}
					>
						{/* Splitter handle */}
						{isPinned && (
							// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
							<div
								className={clsx(
									'absolute z-[1102] left-[-5px] top-0 bottom-0 w-[10px] cursor-col-resize',
									// Cool gradient effect that shows the handle
									'before:absolute before:inset-0',
									'before:opacity-[--ai-splitter-opacity] hover:before:opacity-100',
									'before:transition-opacity before:duration-200',
									'before:bg-gradient-to-r before:from-blue-500/20 before:via-blue-500/10 before:to-transparent',
									'before:backdrop-blur-sm',
								)}
								style={{ '--ai-splitter-opacity': '0' } as React.CSSProperties}
								role="separator"
								onMouseDown={handleMouseDown}
							/>
						)}

						<SmartsErrorBoundary onReset={handleReset}>
							{shouldRender && (
								<SmartsContent visible={visible} setVisible={handleSetVisible} />
							)}
						</SmartsErrorBoundary>
					</div>
				</div>
			)}
		</>
	);
}
