import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Badge } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { Button } from '../../components/Button/Button';
import SystemData from '../../lib/systemData';
import LoginGraphic from '../../assets/login_graphic.png';
import OktaLogo from '../../assets/ssoProviders/Okta_Wordmark_Black_S.png';
import GoogleLogo from '../../assets/ssoProviders/Google_G.svg';
import EntraLogo from '../../assets/ssoProviders/Microsoft_Entra_ID_color_icon.svg';
import { MiscTasks } from '../../api/relevant';
import withIdentity from '../../containers/withIdentity';
import Spinner from '../../components/Spinner';

export const LoginPage = withIdentity()(({
	tryLogin, isAdministrator, isProgrammaticPublisher, isAudiencePublisher, redirect,
}) => {
	const { ssoEnabled, ssoProvider, inDemoMode, demoRequireLogin } = SystemData.genericData;
	const { loginLogoUrl, loginLogoUrlDefault } = SystemData.genericData.systemSettings;
	const hasCustomBranding = loginLogoUrl !== loginLogoUrlDefault;

	const [page, setPage] = useState<'login' | 'sso' | 'sso-signing-in' | 'forgot' | 'forgot-success'>(ssoEnabled ? 'sso' : 'login');
	const [email, setEmail] = useState((inDemoMode && !demoRequireLogin) ? 'demo' : '');
	const [password, setPassword] = useState((inDemoMode && !demoRequireLogin) ? 'demo' : '');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const location = useLocation();
	const history = useHistory();
	const emailRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);

		// SAML login
		if (urlParams.get('saml_access_code')) {
			(async () => {
				try {
					setPage('sso-signing-in');
					await performLogin(null, null, { saml_access_code: urlParams.get('saml_access_code') });
				} catch (e) {
					setPage('sso');
				}
			})();
		}

		// Google OAuth login
		if (urlParams.get('code')) {
			(async () => {
				try {
					setPage('sso-signing-in');
					await performLogin(null, null, { code: urlParams.get('code') });
				} catch (e) {
					setPage('login');
				}
			})();
		}

		setTimeout(() => {
			emailRef.current?.focus();
		});
	}, []);

	const handleLogin = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			await performLogin(email, password);
		} catch (e) {
			setPage('login');
			setTimeout(() => {
				passwordRef.current?.focus();
				passwordRef.current?.select();
			});
		}
	};

	const handleGoogleLogin = async (credentials: { code: string }) => {
		try {
			await performLogin(null, null, credentials);
		} catch (e) {
			setError('Google login failed. Please try again.');
			setIsLoading(false);
			setPage('login');
			throw e;
		}
	};

	const handleForgotPassword = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			await MiscTasks.call('sendPasswordSelectToken', { email });
			setPage('forgot-success');
		} catch (e) {
			setError('Failed to send password recovery email. Please try again.');
		}
	};

	const handleChooseADifferentMethod = () => {
		setPage('login');
		setTimeout(() => {
			emailRef.current?.focus();
		});
	};

	async function startSsoLogin() {
		setIsLoading(true);
		setPage('sso-signing-in');
		try {
			const urlParams = new URLSearchParams(location.search);
			const ssoUrl = await MiscTasks.call('getSsoRedirectUrl', { state: urlParams.get('redirect') });
			window.location.href = ssoUrl;
		} catch (e) {
			setError('SSO login failed. Please try a different method, or contact support.');
			setIsLoading(false);
			setPage('login');
			throw e;
		}
	}

	async function performLogin(email: string | null, password: string | null, credentials?: Record<string, string>) {
		try {
			setIsLoading(true);
			const res = await tryLogin(email, password, credentials);

			if (redirect || res.result?.userNoData?.redirect) {
				history.push(redirect ?? res.result?.userNoData?.redirect);
			} else if (isAdministrator()) {
				history.push('/dashboard');
			} else if (isAudiencePublisher()) {
				history.push('/audience/dashboard');
			} else if (isProgrammaticPublisher()) {
				history.push('/dashboard');
			} else {
				setIsLoading(false);
			}
		} catch (e) {
			setIsLoading(false);
			setError(e.message ?? e.error ?? 'Login failed. Please try again.');
			throw e;
		}
	}

	return (
		<div className="flex min-h-screen overflow-hidden" style={{ background: 'radial-gradient(circle at 80% 100%, #154597 0%, #0F131C 70%)' }}>
			{/* Left side - Product diagram */}
			<div className={clsx(
				// Floating box for smaller screens
				'hidden',
				// Always use floating box for custom branding
				!hasCustomBranding && 'xl:flex xl:flex-1 xl:flex-shrink items-center justify-center relative',
			)}>
				<img src={LoginGraphic} alt="Relevant Yield" className="w-[90%] h-auto max-h-[90%] object-contain animate-fade-in" />
			</div>

			{/* Right side - Login form */}
			<div className={clsx(
				'flex flex-none items-center justify-center w-full',
				!hasCustomBranding && 'xl:w-[500px] xl:bg-white 2xl:w-[600px] 3xl:w-[50vw]',
			)}>
				<div className={clsx(
					'flex items-center justify-center',
					// Floating box for smaller screens
					'max-xl:bg-white max-xl:w-[500px] max-xl:h-[700px] max-xl:rounded-lg max-xl:shadow-lg max-xl:p-8',
					// Always use floating box for custom branding
					hasCustomBranding && 'bg-white w-[500px] h-[700px] rounded-lg shadow-lg p-8',
				)}>
					<div className="flex flex-col gap-8 w-[350px]">
						{/* Logo */}
						<div className="flex justify-center mb-8">
							<img
								src={loginLogoUrl}
								alt="Relevant Yield"
								className="w-[240px]"
							/>
						</div>

						{/* Sign in form */}
						{(page === 'login' || page === 'sso') && (
							<div className="animate-fade-in">
								<h1 className="text-2xl font-medium text-center text-steel-600 mb-6">
									Sign in
								</h1>

								{error && (
									<div className="flex flex-col gap-4 items-center w-full mb-6 animate-expand">
										<p className="text-red-500 text-center">{error}</p>
									</div>
								)}

								{page === 'login' && (
									<div className="flex flex-col gap-8 items-center w-full">
										<form id="login-form" onSubmit={handleLogin} className="flex flex-col gap-4 items-center w-full">
											<input
												ref={emailRef}
												id="email"
												type="email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												className="w-full h-[40px] px-4 py-3 border border-grey-300 rounded-md focus:outline-none focus:ring-2 focus:ring-night-blue-500 focus:border-transparent"
												placeholder="Email"
												required
												disabled={isLoading}
											/>

											<input
												ref={passwordRef}
												id="password"
												type="password"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												className="w-full h-[40px] px-4 py-3 border border-grey-300 rounded-md focus:outline-none focus:ring-2 focus:ring-night-blue-500 focus:border-transparent"
												placeholder="Password"
												required
												disabled={isLoading}
											/>

											<Button
												onClick={handleLogin}
												disabled={isLoading}
												variant="contained"
												color="primary"
												className="w-full h-[40px]"
												type="submit"
											>
												{isLoading ? 'Signing in...' : 'Continue'}
											</Button>

											{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
											<button
												onClick={() => setPage('forgot')}
												className="text-sm text-[#1B68E3] hover:text-night-blue-800 font-semibold h-[40px]"
											>
												Forgot password?
											</button>
										</form>

										<div className="flex flex-row gap-4 items-center w-full">
											<div className="w-full border-t border-grey-400" />
											<span className="text-grey-400">or</span>
											<div className="w-full border-t border-grey-400" />
										</div>

										<div className="flex flex-col gap-4 items-center w-full">
											{/* Disable Google OAuth if Google Workspace is used for SAML */}
											{ssoProvider !== 'google' && (
												<GoogleOAuthProvider clientId={SystemData.genericData.clientId}>
													<GLogin authenticate={handleGoogleLogin} disabled={isLoading} />
												</GoogleOAuthProvider>
											)}

											{ssoEnabled && (
												<SsoProviderButton provider={ssoProvider} onClick={() => startSsoLogin()} disabled={isLoading} />
											)}
										</div>
									</div>
								)}

								{page === 'sso' && (
									<div className="flex flex-col gap-8 items-center">
										<SsoProviderButton provider={ssoProvider} onClick={() => startSsoLogin()} disabled={isLoading} />

										<div className="flex flex-row gap-4 items-center w-full">
											<div className="w-full border-t border-grey-400" />
											<span className="text-grey-400">or</span>
											<div className="w-full border-t border-grey-400" />
										</div>

										<button
											className="text-sm text-[#1B68E3] hover:text-night-blue-800 font-semibold"
											onClick={handleChooseADifferentMethod}
										>
											Choose a different method
										</button>
									</div>
								)}
							</div>
						)}

						{/* "Signing in" page (only shown during the SSO flow) */}
						{page === 'sso-signing-in' && (
							<div className="flex flex-col gap-4 items-center w-full animate-fade-in-up">
								<h1 className="text-2xl font-medium text-center text-steel-600 mb-6">
									Signing you in...
								</h1>
								<Spinner />
							</div>
						)}
						{/* Forgot password page */}
						{page === 'forgot' && (
							<div className="animate-fade-in">
								<h1 className="text-2xl font-medium text-center text-steel-600 mb-6">
									Recover password
								</h1>

								<form id="forgot-password-form" onSubmit={handleForgotPassword} className="flex flex-col gap-4 items-center w-full">
									<input
										type="email"
										placeholder="Email"
										className="w-full h-[40px] px-4 py-3 border border-grey-300 rounded-md focus:outline-none focus:ring-2 focus:ring-night-blue-500 focus:border-transparent"
										required
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										disabled={isLoading}
									/>

									<Button
										onClick={handleForgotPassword}
										disabled={isLoading}
										variant="contained"
										color="primary"
										className="w-full h-[40px]"
										type="submit"
									>
										Continue
									</Button>

									<button
										className="text-sm text-[#1B68E3] hover:text-night-blue-800 font-semibold h-[40px]"
										onClick={handleChooseADifferentMethod}
									>
										Back to Sign In
									</button>
								</form>
							</div>
						)}

						{page === 'forgot-success' && (
							<div className="animate-fade-in">
								<h1 className="text-2xl font-medium text-center text-steel-600 mb-6">
									Recover password
								</h1>

								<p className="text-sm text-center text-steel-600 mb-6">
									An email has been sent to you with instructions on how to reset your password.
								</p>

								<button
									className="text-sm text-[#1B68E3] hover:text-night-blue-800 font-semibold w-full h-[40px]"
									onClick={handleChooseADifferentMethod}
								>
									Back to Sign In
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
});

function SsoProviderButton({ provider, disabled, onClick }: { provider?: string, disabled?: boolean, onClick: () => void }) {
	return (
		<button
			type="button"
			className="w-full flex items-center justify-center py-[10px] px-4 border border-grey-300 hover:border-grey-400 active:border-[#999999] rounded-md hover:shadow-sm bg-white text-sm font-medium text-steel-600 hover:bg-grey-50 disabled:opacity-50 disabled:cursor-not-allowed"
			onClick={onClick}
			disabled={disabled}
		>
			{provider === 'entra' && (
				<div className="flex items-center justify-center gap-2">
					<img src={EntraLogo} className="h-5 w-auto" />
					Continue with Microsoft Entra ID
				</div>
			)}

			{provider === 'okta' && (
				<div className="flex items-center justify-center">
					<span className="relative top-[1px]">Continue with</span>
					<img src={OktaLogo}  className="h-6 w-auto my-[-2px]" />
				</div>
			)}

			{provider === 'google' && (
				<div className="flex items-center justify-center gap-2">
					<img src={GoogleLogo} className="h-5 w-auto" />
					Continue with Google Workspace
				</div>
			)}

			{!provider && (
				<div className="flex items-center justify-center gap-2">
					<Badge className="h-5 w-auto" />
					<span className="relative top-[1px]">Continue with Company Account</span>
				</div>
			)}
		</button>
	);
}

function GLogin({ disabled } : { disabled: boolean }) {
	const login = useGoogleLogin({
		state: `${window.location}`,
		ux_mode: 'redirect',
		redirect_uri: SystemData.genericData.OAUTH_URL,
		flow: 'auth-code',
	});

	return (
		<button
			type="button"
			className="w-full flex items-center justify-center py-[10px] px-4 border border-grey-300 hover:border-grey-400 active:border-[#999999] rounded-md hover:shadow-sm bg-white text-sm font-medium text-steel-600 hover:bg-grey-50"
			disabled={disabled}
			onClick={login}
		>
			<img src={GoogleLogo} alt="G" className="h-5 w-auto mr-1" />
			Continue with Google
		</button>
	);
}
